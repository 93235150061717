import Products from "../api/productApi";
import moment from "moment";
import HttpCodes from "../library/HttpCodes";


const _productStore = {
    timestamp : null,
    loading : false,
    products : [],
    productList : []
}

class ProductStoreClass {

    refresh = async (force = false) => {
        if (_productStore.loading) {
            return _productStore ;
        }

        if (!force && _productStore.products.length > 0) {
            return _productStore ;
        }

        try {
            _productStore.loading = true ;
            let result = await Products.getProducts() ;
            if (result.status === HttpCodes.HttpOK) {
                let prods = result.data
                let dispprods = prods.map((p, oi) => {
                    return {"value": p.productid, "label": `${p.productname}`, "display": `(${p.product_family}) - ${p.productname}`,target: p};
                });

                _productStore.products = prods ;
                _productStore.productList = dispprods ;
                _productStore.timestamp = moment.utc().format();
            }
        }
        catch(e) {
            console.error("Problem loading products - ", e.message);
        }
        finally {
            _productStore.loading = false ;
        }

        return _productStore ;
    }
}

const ProductStore = new ProductStoreClass();
export default ProductStore ;

