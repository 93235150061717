import {useEffect, useState} from "react";
import CustomerSelectList from "../devices/CustomerSelectList";
import Lifts from "../../api/liftApi";
import {CustomerSearchBuilderView} from "../search/CustomerSearchBuilderView";
import {useNavigate} from "react-router-dom";
import LinkBuilder from "../../domain/LinkBuilder";
import Helper from "../../library/Helper";


const CustomerDetailView = ({customer, onCustomerSelected, onSearchStart, onSearchClose}) => {
    const gotopage = useNavigate() ;

    const [selectingCustomer, setSelectingCustomer] = useState(false) ;
    const [customers, setCustomers] = useState([]) ;

    const edit = () => {
        LinkBuilder.customer(gotopage, customer.customer_id) ;
    }

    const addNew = () => {
        LinkBuilder.customer(gotopage, "new");
    }

    const onSelectCustomer = (c) => {
        setSelectingCustomer(false) ;
        if (onCustomerSelected) {
            onCustomerSelected(c) ;
        }
    }

    const onStartSearchCustomer = () => {
        setSelectingCustomer(true) ;
        if (onSearchStart) {
            onSearchStart() ;
        }
    }

    const onEndSearchCustomer = () => {
        setSelectingCustomer(false) ;
        if (onSearchClose) {
            onSearchClose() ;
        }
    }

    const searchResults = (cl) => {
        setCustomers(cl || []) ;
    }


    if (selectingCustomer) {
        return (
            <div className="lift-header-column" style={{marginTop: '10px'}}>
                <CustomerSearchBuilderView onSearchResults={searchResults} allowAddCustomer={true} onClose={onEndSearchCustomer} />

                {(customers.length === 0) &&
                    <div>
                        <span>There are no customers to display</span>
                    </div>
                }

                {(customers.length > 0) &&
                    <CustomerSelectList
                        customers={customers}
                        onSelect={onSelectCustomer}
                    />
                }

            </div>
        );
    }

    if (!customer) {
        return (
            <div className="lift-header-column" style={{marginTop: '10px'}}>
                <h5>Not currently assigned to a customer</h5>
                <button  onClick={onStartSearchCustomer}>
                    <span className="material-icons" style={{verticalAlign: '-6px', paddingRight: '8px'}}  >person_add</span>Select
                </button>
                <button  onClick={addNew}>
                    <span className="material-icons" style={{verticalAlign: '-6px', paddingRight: '8px'}}  >add</span>Create
                </button>
            </div>
        );
    }

    return (
        <div >
            <div  className="section-header">Customer</div>

            <div>
                {/*Left Side*/}
                <div style={{display: 'inline-block', verticalAlign: 'top'}}>
                    <div className="lift-header-column" >
                        <div className="section-edit-row">
                            <label style={{width: '120px',  display : 'inline-block'}}>Contact Name</label>
                            <input type="text"
                                   style={{width: '280px'}}
                                   disabled
                                   placeholder="contact name"
                                   value={customer.contact_name}
                            />
                        </div><br/>
                        <div className="section-edit-row">
                            <label style={{width: '120px',  display : 'inline-block'}}>Contact Phone #</label>
                            <input type="text"
                                   style={{width: '180px'}}
                                   disabled
                                   placeholder="contact phone number"
                                   value={customer.contact_phone}
                            />
                        </div><br/>
                        {/*<div className="section-edit-row">*/}
                        {/*    <label style={{width: '100px',  display : 'inline-block'}}>Lat / Long</label>*/}
                        {/*    <input type="text"*/}
                        {/*           style={{width: '120px'}}*/}
                        {/*           disabled*/}
                        {/*           placeholder="latitude"*/}
                        {/*           value={customer.geo_latitude}*/}
                        {/*    />*/}
                        {/*    <span style={{paddingLeft: '10px', paddingRight: '10px'}}>/</span>*/}
                        {/*    <input type="text"*/}
                        {/*           style={{width: '120px'}}*/}
                        {/*           disabled*/}
                        {/*           placeholder="longitude"*/}
                        {/*           value={customer.geo_longitude}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {/*<br/>*/}

                    </div>

                </div>

                {/*Right Side*/}
                <div style={{display: 'inline-block', verticalAlign: 'top'}}>
                    <div className="section-edit-row">
                        <label style={{width: '120px',  display : 'inline-block', verticalAlign: 'top', textAlign: 'right', marginRight: '10px'}}>Address</label>
                        <div className="non-editable-text" style={{display : 'inline-block'}}>
                            {(customer.addr_line_1) && <div className="lift-address-text">{customer.addr_line_1}</div>}
                            {(customer.addr_line_2) && <div className="lift-address-text">{customer.addr_line_2}</div>}
                            {(customer.addr_line_3) && <div className="lift-address-text">{customer.addr_line_3}</div>}
                            {(customer.addr_town) && <div className="lift-address-text">{customer.addr_town}</div>}
                            {(customer.addr_county) && <div className="lift-address-text">{customer.addr_county}</div>}
                            {(customer.addr_postcode) && <div className="lift-address-text">{customer.addr_postcode}</div>}
                        </div>

                    </div>
                    <br/>
                </div>
            </div>

            <br/>
            <div>
                <button  onClick={edit}>
                    <span className="material-symbols-sharp" style={{verticalAlign: '-6px', paddingRight: '8px'}}  >settings_account_box</span>Edit Detail
                </button>
                <button className="button-delete" onClick={onStartSearchCustomer}>
                    <span className="material-icons" style={{verticalAlign: '-6px', paddingRight: '8px'}}  >manage_accounts</span>Change Customer
                </button>

                <div style={{display : 'inline-block', float: 'right'}}>
                    <span style={{fontSize: 'x-small', color: 'blue'}}>Last Edited {Helper.dbTimeToDisplayTime(customer.last_updated)}</span>
                </div>
            </div>

        </div>

    )

}

export default CustomerDetailView;