
const HttpCodes = {
    HttpOK : 200,
    HttpCreated: 201,
    HttpNoContent : 204,
    HttpBadRequest : 400,
    HttpForbidden : 403,
    HttpNotFound : 404,
    HttpNotAuthorised : 401,
    HttpInternalError : 500,
    HttpNotImplemented : 501
}


export default HttpCodes

