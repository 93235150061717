import LinkBuilder from "../../domain/LinkBuilder";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Lifts from "../../api/liftApi";
import HttpCodes from "../../library/HttpCodes";
import Helper from "../../library/Helper";
import {ConnectionStatus} from "../../library/connectionStatus";
import {ProductTypes} from "../../domain/SystemTypes";

import duo_logo from "../../assets/Duo-Hybrid-White.png";
import trio_logo from "../../assets/Trio-Hybrid-White.png";
import classic_logo from "../../assets/TCL-White.png";
import EventsApi from "../../api/eventApi";
import moment from "moment";

export const EventCountsHomeTile = ({link, onRemove}) => {
    const gotopage = useNavigate() ;

    const [events, setEvents] = useState([]);
    const [lastUpdated, setLastUpdated] = useState(null) ;
    const [error, setError] = useState(null) ;

    useEffect(() => {
        refreshState() ;
        const interval = setInterval(() => {
            refreshState() ;
        }, 60000 * 5);
        return () => clearInterval(interval);
    }, []);

    const refreshState = async () => {
        try {
            let state = await EventsApi.nonClosedEventCounts() ;
            console.log("EventCountsHomeTile", state) ;
            if (state.status === HttpCodes.HttpOK) {
                setEvents(state.data) ;
                setError(null) ;
            }
            else {
                setError("Unable to refresh state")
            }

            setLastUpdated(moment()) ;
        }
        catch(e) {
            console.error(e) ;
            setError("Unable to refresh state")
        }
    }

    const selectLink = (l) => {
        console.log("Select link: ", l) ;
        LinkBuilder.triggerEvents(gotopage, l.rule_set_id) ;
    }


    return (
        <div style={{display: 'inline-block', margin: '2px', cursor: 'pointer'}}>
            <h4>Trigger Event Summary <div className="material-icons"
                                           style={{
                                               fontSize: '16pt',
                                               marginRight: '3px',
                                               verticalAlign: '-6px',
                                               marginLeft: '30px'
                                           }}
                                           onClick={refreshState}>refresh</div></h4>

            <div style={{fontStyle: 'italic'}}>
                <small>Updated: {Helper.dbTimeToDisplayTime(lastUpdated)}</small></div>

            {(events.length === 0) &&
                <div style={{color: 'darkblue', fontSize: '28px'}}>No open events found</div>
            }

            {(events.length > 0) &&
                <table style={{backgroundColor: 'white'}}>
                    <thead>
                    <tr key={`lde_hdr`}>
                        <th style={{width: '30px'}}></th>
                        <th style={{width: '200px'}}>Rule</th>
                        <th style={{width: '140px', textAlign: 'center'}}>Monitoring</th>
                        <th style={{width: '140px', textAlign: 'center'}}>Open</th>
                    </tr>
                    </thead>
                    <tbody>

                    {(events.map((e, ex) => {
                        return (
                            <tr key={`lde_${ex}`}>
                                <td>
                                    <div className="image-button"><span className="material-icons" title="View Data"
                                                                        style={{
                                                                            verticalAlign: '0px',
                                                                            fontSize: '16px'
                                                                        }}
                                                                        onClick={() => selectLink(e)}>query_stats</span>
                                    </div>
                                </td>
                                <td style={{fontSize: '14px'}}>
                                    <b style={{color:'darkred'}}>{e.rule_set_name}</b><br/><small>{e.rule_set_desc}</small></td>
                                <td style={{
                                    fontSize: '34px',
                                    textAlign: 'center'
                                }}>{Helper.asNumberString(e.in_progress_count)}</td>
                                <td style={{
                                    fontSize: '34px',
                                    textAlign: 'center'
                                }}>{Helper.asNumberString(e.open_count)}</td>
                            </tr>
                        )
                    }))
                    }
                    </tbody>
                </table>
            }


        </div>
    )
}