

export const TimelinePreferenceDefaults = {
    "groups" : [
        {
            "name" : "Default",
            "items" : []
        }
    ],
}


export const SessionDefaults = {
    engineer_show_groups : true,
    timeline_io_open : true,
    timeline_show_groups : true,
}