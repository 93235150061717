import LinkBuilder from "../../domain/LinkBuilder";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Lifts from "../../api/liftApi";
import HttpCodes from "../../library/HttpCodes";
import Helper from "../../library/Helper";
import {ConnectionStatus} from "../../library/connectionStatus";
import Search from "../../api/searchApi";
import moment from "moment";


export const SearchHomeTile = ({link, onRemove}) => {
    const gotopage = useNavigate() ;

    const [lastUpdated, setLastUpdated] = useState(null) ;
    const [count, setCount] = useState(0) ;
    const [error, setError] = useState(null) ;

    useEffect(() => {
        refreshState() ;
        const interval = setInterval(() => {
            refreshState() ;
        }, 60000 * 5);
        return () => clearInterval(interval);
    }, []);

    const refreshState = async () => {
        // console.log("Search link", link)
        try {
            let state = await Search.searchCounts(link) ;
            if (state.status === HttpCodes.HttpOK) {
                setLastUpdated(moment().format()) ;
                setCount(state.data.count) ;
                setError(null) ;
            }
            else {
                setError("Unable to refresh counts")
                setCount("?")
            }
        }
        catch(e) {
            console.error(e) ;
            setError("Unable to refresh counts")
            setCount("?")
        }
    }

    const selectPredefined = (p) => {
        LinkBuilder.menuSearchPredefined(gotopage, p.name) ;
    }


    return (
        <div className={"predefined-search-pill"} style={{display: 'inline-block', margin: '2px', cursor: 'pointer'}}>
            <div  onClick={() => selectPredefined(link)}>
                <div style={{paddingBottom: '10px', textAlign: 'left'}}>
                    <span className="material-symbols-outlined" style={{display: 'inline-block', marginRight: '4px', verticalAlign : '-6px'}}>{link.search_view === 1 ? "map" : "table_rows"}</span>
                    <span style={{display: 'inline-block'}}>{link.name}</span>
                </div>

                <div >
                    <div style={{display: 'inline-block', width: '50%', textAlign: 'left', wordWrap: 'break-word', verticalAlign: 'top'}}>
                        {error && <div style={{textAlign: 'left', marginTop: '5px', fontStyle: 'italic'}} ><small>{error}</small></div>}
                        {!error && <div style={{textAlign: 'left', marginTop: '5px', fontStyle: 'italic'}} ><small>{link.description}</small></div>}
                    </div>
                    <div style={{display: 'inline-block', width: '50%'}}>
                        <div style={{textAlign: 'center', marginTop: '5px', fontSize: '42px', color: 'darkblue'}} >{count}</div>
                    </div>
                </div>

                <div style={{textAlign: 'center', marginTop: '3px', fontStyle: 'italic'}} ><small>Updated: {Helper.dbTimeToDisplayTime(lastUpdated)}</small></div>
            </div>

            <div style={{marginTop: '10px'}}>
                <div style={{display: 'inline-block', float: 'left'}}><span className="material-icons" style={{fontSize: '16pt', marginRight: '3px', verticalAlign : '-6px'}} onClick={() => onRemove(link)}>cancel</span></div>
                <div style={{display: 'inline-block', float: 'right'}}>
                    <div className="material-icons" style={{fontSize: '16pt', marginRight: '3px', verticalAlign : '-6px'}} onClick={refreshState}>refresh</div>
                </div>
            </div>
        </div>
    )
}