

export const L475Lookup = {
    byId: {
        1: "LOG_NONE",
        2: "LOG_RESET_POR",
        3: "LOG_RESET_PIN",
        4: "LOG_RESET_COP",
        5: "LOG_RESET_ILOP",
        6: "LOG_RESET_LVD",
        7: "LOG_A2D",
        8: "LOG_STATE_MACHINE",
        9: "LOG_BRAKE_SEMI",
        10: "LOG_BRAKE_OPEN",
        11: "LOG_RELAY_OPEN",
        12: "LOG_RELAY_CLOSED",
        13: "LOG_OVER_CURRENT",
        14: "LOG_BRIDGE",
        15: "LOG_CHARGE_MACHINE_FAULT",
        16: "LOG_OVER_CURRENT_5",
        17: "LOG_TOP_PRESSURE_5",
        18: "LOG_BOT_PRESSURE_5",
        19: "LOG_TOP_STOP_5",
        20: "LOG_BOT_STOP_5",
        21: "LOG_DEFAULT_EEPROM",
        22: "LOG_DEFAULT_LOG",
        23: "LOG_DEFAULT_COUNT",
        24: "LOG_ELECTRONICS",
        25: "LOG_CHECKSUM",
        26: "LOG_OVERVOLTAGE",
        27: "LOG_UNDERVOLTAGE",
        28: "LOG_FET_HIGH",
        29: "LOG_FET_LOW",
        30: "LOG_UNKNOWN_DIRECTION",
        31: "LOG_F301",
        32: "LOG_SAFETY_UP_FAULT",
        33: "LOG_SAFETY_DOWN_FAULT",
        34: "LOG_DOOR_LOCK_FAULT_HZ",
        35: "LOG_DOOR_LOCK_FAULT_LSS",
        36: "LOG_DOOR_OPEN",
        37: "LOG_MECHANICAL_OVERLOAD",
        38: "LOG_OVERLOAD_PREVENT_MOVE",
        39: "LOG_MOTOR_FAULT",
        40: "LOG_RS485_FAULT",
        41: "LOG_FIRE_ALARM",
        42: "LOG_LOW_VOLTAGE_FAULT",
        43: "LOG_BUTTON_RELEASE",
        44: "LOG_FLOOR_HIT",
        45: "LOG_SAFETY_CHAIN",
        46: "LOG_REMOTE_OVERRIDE",
        47: "LOG_NO_RELAY_POWER",
        48: "LOG_F301_REQ",
        49: "LOG_MOTOR_RAMP_UP_FAIL",
        50: "LV_LOG_DOOR_LOCK_FAULT_HZ",
        51: "LV_LOG_DOOR_LOCK_FAULT_LSS",
        52: "OVRL_LOG_DOOR_LOCK_FAULT_HZ",
        53: "OVRL_LOG_DOOR_LOCK_FAULT_LSS",
        54: "LOG_CHARGER_FAULT",
        55: "LOG_ENG_OVR_OFF_WAIT",
        56: "LOG_BOTTOM_FLOOR_HIT",
        57: "LOG_DOOR_OPEN_BUTTON_RELEASE",
        58: "LOG_RAMP_DIR_UNKNOWN",
        59: "LOG_F301_MOTOR_RUNNING",
        60: "LOG_AUTO_DOOR_UNKNOWN_ACTION",
        61: "LOG_SAFETY_KEYSWITCH",
        62: "LOG_SAFETY_ESTOP",
        63: "LOG_SAFETY_SPARES",
        64: "LOG_SAFETY_LIGHTGUARD",
        65: "LOG_SAFETY_DSS",
        66: "LOG_SAFETY_HZ",
        67: "LOG_SAFETY_LSS",
        68: "LOG_SAFETY_TOP_LIMITS",
        69: "LOG_SAFETY_BOT_LIMITS",
        70: "LOG_SAFETY_UP_COMMON",
        71: "LOG_SAFETY_DN_COMMON",
        72: "LOG_SAFETY_PWR_COMMON",
        73: "LOG_SAFETY_UNKNOWN",
        74: "LOG_LOW_VOLTAGE_PREVENT_MOVE"
    },
    byLabel: {
        "LOG_NONE": 1,
        "LOG_RESET_POR": 2,
        "LOG_RESET_PIN": 3,
        "LOG_RESET_COP": 4,
        "LOG_RESET_ILOP": 5,
        "LOG_RESET_LVD": 6,
        "LOG_A2D": 7,
        "LOG_STATE_MACHINE": 8,
        "LOG_BRAKE_SEMI": 9,
        "LOG_BRAKE_OPEN": 10,
        "LOG_RELAY_OPEN": 11,
        "LOG_RELAY_CLOSED": 12,
        "LOG_OVER_CURRENT": 13,
        "LOG_BRIDGE": 14,
        "LOG_CHARGE_MACHINE_FAULT": 15,
        "LOG_OVER_CURRENT_5": 16,
        "LOG_TOP_PRESSURE_5": 17,
        "LOG_BOT_PRESSURE_5": 18,
        "LOG_TOP_STOP_5": 19,
        "LOG_BOT_STOP_5": 20,
        "LOG_DEFAULT_EEPROM": 21,
        "LOG_DEFAULT_LOG": 22,
        "LOG_DEFAULT_COUNT": 23,
        "LOG_ELECTRONICS": 24,
        "LOG_CHECKSUM": 25,
        "LOG_OVERVOLTAGE": 26,
        "LOG_UNDERVOLTAGE": 27,
        "LOG_FET_HIGH": 28,
        "LOG_FET_LOW": 29,
        "LOG_UNKNOWN_DIRECTION": 30,
        "LOG_F301": 31,
        "LOG_SAFETY_UP_FAULT": 32,
        "LOG_SAFETY_DOWN_FAULT": 33,
        "LOG_DOOR_LOCK_FAULT_HZ": 34,
        "LOG_DOOR_LOCK_FAULT_LSS": 35,
        "LOG_DOOR_OPEN": 36,
        "LOG_MECHANICAL_OVERLOAD": 37,
        "LOG_OVERLOAD_PREVENT_MOVE": 38,
        "LOG_MOTOR_FAULT": 39,
        "LOG_RS485_FAULT": 40,
        "LOG_FIRE_ALARM": 41,
        "LOG_LOW_VOLTAGE_FAULT": 42,
        "LOG_BUTTON_RELEASE": 43,
        "LOG_FLOOR_HIT": 44,
        "LOG_SAFETY_CHAIN": 45,
        "LOG_REMOTE_OVERRIDE": 46,
        "LOG_NO_RELAY_POWER": 47,
        "LOG_F301_REQ": 48,
        "LOG_MOTOR_RAMP_UP_FAIL": 49,
        "LV_LOG_DOOR_LOCK_FAULT_HZ": 50,
        "LV_LOG_DOOR_LOCK_FAULT_LSS": 51,
        "OVRL_LOG_DOOR_LOCK_FAULT_HZ": 52,
        "OVRL_LOG_DOOR_LOCK_FAULT_LSS": 53,
        "LOG_CHARGER_FAULT": 54,
        "LOG_ENG_OVR_OFF_WAIT": 55,
        "LOG_BOTTOM_FLOOR_HIT": 56,
        "LOG_DOOR_OPEN_BUTTON_RELEASE": 57,
        "LOG_RAMP_DIR_UNKNOWN": 58,
        "LOG_F301_MOTOR_RUNNING": 59,
        "LOG_AUTO_DOOR_UNKNOWN_ACTION": 60,
        "LOG_SAFETY_KEYSWITCH": 61,
        "LOG_SAFETY_ESTOP": 62,
        "LOG_SAFETY_SPARES": 63,
        "LOG_SAFETY_LIGHTGUARD": 64,
        "LOG_SAFETY_DSS": 65,
        "LOG_SAFETY_HZ": 66,
        "LOG_SAFETY_LSS": 67,
        "LOG_SAFETY_TOP_LIMITS": 68,
        "LOG_SAFETY_BOT_LIMITS": 69,
        "LOG_SAFETY_UP_COMMON": 70,
        "LOG_SAFETY_DN_COMMON": 71,
        "LOG_SAFETY_PWR_COMMON": 72,
        "LOG_SAFETY_UNKNOWN": 73,
        "LOG_LOW_VOLTAGE_PREVENT_MOVE": 74
    },
    descriptions: {
        1 : "LOG_NONE",
        2 : "LOG_RESET_POR",
        3 : "LOG_RESET_PIN",
        4 : "LOG_RESET_COP",
        5 : "LOG_RESET_ILOP",
        6 : "LOG_RESET_LVD",
        7 : "LOG_A2D",
        8 : "LOG_STATE_MACHINE",
        9 : "LOG_BRAKE_SEMI",
        10 : "LOG_BRAKE_OPEN",
        11 : "LOG_RELAY_OPEN",
        12 : "LOG_RELAY_CLOSED",
        13 : "LOG_OVER_CURRENT",
        14 : "LOG_BRIDGE",
        15 : "LOG_CHARGE_MACHINE_FAULT",
        16 : "LOG_OVER_CURRENT_5",
        17 : "LOG_TOP_PRESSURE_5",
        18 : "LOG_BOT_PRESSURE_5",
        19 : "LOG_TOP_STOP_5",
        20 : "LOG_BOT_STOP_5",
        21 : "LOG_DEFAULT_EEPROM",
        22 : "LOG_DEFAULT_LOG",
        23 : "LOG_DEFAULT_COUNT",
        24 : "LOG_ELECTRONICS",
        25 : "LOG_CHECKSUM",
        26 : "LOG_OVERVOLTAGE",
        27 : "LOG_UNDERVOLTAGE",
        28 : "LOG_FET_HIGH",
        29 : "LOG_FET_LOW",
        30 : "LOG_UNKNOWN_DIRECTION",
        31 : "LOG_F301",
        32 : "LOG_SAFETY_UP_FAULT",
        33 : "LOG_SAFETY_DOWN_FAULT",
        34 : "LOG_DOOR_LOCK_FAULT_HZ",
        35 : "LOG_DOOR_LOCK_FAULT_LSS",
        36 : "LOG_DOOR_OPEN",
        37 : "LOG_MECHANICAL_OVERLOAD",
        38 : "LOG_OVERLOAD_PREVENT_MOVE",
        39 : "LOG_MOTOR_FAULT",
        40 : "LOG_RS485_FAULT",
        41 : "LOG_FIRE_ALARM",
        42 : "LOG_LOW_VOLTAGE_FAULT",
        43 : "LOG_BUTTON_RELEASE",
        44 : "LOG_FLOOR_HIT",
        45 : "LOG_SAFETY_CHAIN",
        46 : "LOG_REMOTE_OVERRIDE",
        47 : "LOG_NO_RELAY_POWER",
        48 : "LOG_F301_REQ",
        49 : "LOG_MOTOR_RAMP_UP_FAIL",
        50 : "LV_LOG_DOOR_LOCK_FAULT_HZ",
        51 : "LV_LOG_DOOR_LOCK_FAULT_LSS",
        52 : "OVRL_LOG_DOOR_LOCK_FAULT_HZ",
        53 : "OVRL_LOG_DOOR_LOCK_FAULT_LSS",
        54 : "LOG_CHARGER_FAULT",
        55 : "LOG_ENG_OVR_OFF_WAIT",
        56 : "LOG_BOTTOM_FLOOR_HIT",
        57 : "LOG_DOOR_OPEN_BUTTON_RELEASE",
        58 : "LOG_RAMP_DIR_UNKNOWN",
        59 : "LOG_F301_MOTOR_RUNNING",
        60 : "LOG_AUTO_DOOR_UNKNOWN_ACTION",
        61 : "LOG_SAFETY_KEYSWITCH",
        62 : "LOG_SAFETY_ESTOP",
        63 : "LOG_SAFETY_SPARES",
        64 : "LOG_SAFETY_LIGHTGUARD",
        65 : "LOG_SAFETY_DSS",
        66 : "LOG_SAFETY_HZ",
        67 : "LOG_SAFETY_LSS",
        68 : "LOG_SAFETY_TOP_LIMITS",
        69 : "LOG_SAFETY_BOT_LIMITS",
        70 : "LOG_SAFETY_UP_COMMON",
        71 : "LOG_SAFETY_DN_COMMON",
        72 : "LOG_SAFETY_PWR_COMMON",
        73 : "LOG_SAFETY_UNKNOWN",
        74 : "LOG_LOW_VOLTAGE_PREVENT_MOVE"
    }
};