

export const F301Lookup = {
    byId: {
        0: "LOG_NONE",
        1: "LOG_RESET_POR",
        2: "LOG_RESET_PIN",
        3: "LOG_RESET_COP",
        4: "LOG_RESET_ILOP",
        5: "LOG_RESET_LVD",
        6: "LOG_A2D",
        7: "LOG_STATE_MACHINE",
        8: "LOG_BRAKE_SEMI",
        9: "LOG_BRAKE1_OPEN",
        10: "LOG_RELAY_OPEN",
        11: "LOG_RELAY_CLOSED",
        12: "LOG_OVER_CURRENT",
        13: "LOG_BRIDGE",
        14: "LOG_CHARGE_MACHINE_FAULT",
        15: "LOG_OVER_CURRENT_5",
        16: "LOG_TOP_PRESSURE_5",
        17: "LOG_BOT_PRESSURE_5",
        18: "LOG_TOP_STOP_5",
        19: "LOG_BOT_STOP_5",
        20: "LOG_DEFAULT_EEPROM",
        21: "LOG_DEFAULT_LOG",
        22: "LOG_DEFAULT_COUNT",
        23: "LOG_ELECTRONICS",
        24: "LOG_CHECKSUM",
        25: "LOG_OVERVOLTAGE",
        26: "LOG_UNDERVOLTAGE",
        27: "LOG_FET_HIGH",
        28: "LOG_FET_LOW",
        29: "LOG_UNKNOWN_DIRECTION",
        30: "LOG_BRAKE2_OPEN",
        31: "LOG_OSG",
        32: "LOG_ENCODER_AMBER_WARNING",
        33: "LOG_ENCODER_RED_WARNING",
        34: "LOG_L475_REQ",
        35: "LOG_L475_STOP",
        36: "LOG_ENCODER_ERR",
        37: "LOG_BOTTOM_FLOOR",
        38: "LOG_RAMP_DOWN_TIMEOUT",
        39: "LOG_MOTOR_WRONG_DIR",
        40: "LOG_BRAKE1_DE_ENERGISED",
        41: "LOG_BRAKE2_DE_ENERGISED",
    },
    byLabel: {
        "LOG_NONE": 0,
        "LOG_RESET_POR": 1,
        "LOG_RESET_PIN": 2,
        "LOG_RESET_COP": 3,
        "LOG_RESET_ILOP": 4,
        "LOG_RESET_LVD": 5,
        "LOG_A2D": 6,
        "LOG_STATE_MACHINE": 7,
        "LOG_BRAKE_SEMI": 8,
        "LOG_BRAKE1_OPEN": 9,
        "LOG_RELAY_OPEN": 10,
        "LOG_RELAY_CLOSED": 11,
        "LOG_OVER_CURRENT": 12,
        "LOG_BRIDGE": 13,
        "LOG_CHARGE_MACHINE_FAULT": 14,
        "LOG_OVER_CURRENT_5": 15,
        "LOG_TOP_PRESSURE_5": 16,
        "LOG_BOT_PRESSURE_5": 17,
        "LOG_TOP_STOP_5": 18,
        "LOG_BOT_STOP_5": 19,
        "LOG_DEFAULT_EEPROM": 20,
        "LOG_DEFAULT_LOG": 21,
        "LOG_DEFAULT_COUNT": 22,
        "LOG_ELECTRONICS": 23,
        "LOG_CHECKSUM": 24,
        "LOG_OVERVOLTAGE": 25,
        "LOG_UNDERVOLTAGE": 26,
        "LOG_FET_HIGH": 27,
        "LOG_FET_LOW": 28,
        "LOG_UNKNOWN_DIRECTION": 29,
        "LOG_BRAKE2_OPEN": 30,
        "LOG_OSG": 31,
        "LOG_ENCODER_AMBER_WARNING": 32,
        "LOG_ENCODER_RED_WARNING": 33,
        "LOG_L475_REQ": 34,
        "LOG_L475_STOP": 35,
        "LOG_ENCODER_ERR": 36,
        "LOG_BOTTOM_FLOOR": 37,
        "LOG_RAMP_DOWN_TIMEOUT": 38,
        "LOG_MOTOR_WRONG_DIR": 39,
        "LOG_BRAKE1_DE_ENERGISED": 40,
        "LOG_BRAKE2_DE_ENERGISED": 41,
    },
    descriptions: {
        0: "LOG_NONE",
        1: "LOG_RESET_POR",
        2: "LOG_RESET_PIN",
        3: "LOG_RESET_COP",
        4: "LOG_RESET_ILOP",
        5: "LOG_RESET_LVD",
        6: "LOG_A2D",
        7: "LOG_STATE_MACHINE",
        8: "LOG_BRAKE_SEMI",
        9: "LOG_BRAKE1_OPEN",
        10: "LOG_RELAY_OPEN",
        11: "LOG_RELAY_CLOSED",
        12: "LOG_OVER_CURRENT",
        13: "LOG_BRIDGE",
        14: "LOG_CHARGE_MACHINE_FAULT",
        15: "LOG_OVER_CURRENT_5",
        16: "LOG_TOP_PRESSURE_5",
        17: "LOG_BOT_PRESSURE_5",
        18: "LOG_TOP_STOP_5",
        19: "LOG_BOT_STOP_5",
        20: "LOG_DEFAULT_EEPROM",
        21: "LOG_DEFAULT_LOG",
        22: "LOG_DEFAULT_COUNT",
        23: "LOG_ELECTRONICS",
        24: "LOG_CHECKSUM",
        25: "LOG_OVERVOLTAGE",
        26: "LOG_UNDERVOLTAGE",
        27: "LOG_FET_HIGH",
        28: "LOG_FET_LOW",
        29: "LOG_UNKNOWN_DIRECTION",
        30: "LOG_BRAKE2_OPEN",
        31: "LOG_OSG",
        32: "LOG_ENCODER_AMBER_WARNING",
        33: "LOG_ENCODER_RED_WARNING",
        34: "LOG_L475_REQ",
        35: "LOG_L475_STOP",
        36: "LOG_ENCODER_ERR",
        37: "LOG_BOTTOM_FLOOR",
        38: "LOG_RAMP_DOWN_TIMEOUT",
        39: "LOG_MOTOR_WRONG_DIR",
        40: "LOG_BRAKE1_DE_ENERGISED",
        41: "LOG_BRAKE2_DE_ENERGISED",
    }
};



