import BaseApi from "./baseApi";


class SimApiClass {
    async getSIMsOfStatus(status) {
        return await BaseApi.apiCallWithRefresh(BaseApi.client.get, `/v1/sim/status/search?status=${status}`, null, null);
    }

    async getSIM(sim) {
        return await BaseApi.apiCallWithRefresh(BaseApi.client.get, `/v1/sim/detail?sim=${sim}`, null, null);
    }

    async getTopDataUsageSIMs(limit) {
        return await BaseApi.apiCallWithRefresh(BaseApi.client.get, `/v1/sim/top?limit=${limit}`, null, null);
    }

    async getSIMsOfStatusWithLastUsageBefore(status, minutes) {
        return await BaseApi.apiCallWithRefresh(BaseApi.client.get, `/v1/sim/status/no_data?status=${status}&minutes=${minutes}`, null, null);
    }

    async getSIMStatusCounts() {
        return await BaseApi.apiCallWithRefresh(BaseApi.client.get, `/v1/sim/status/counts`, null, null);
    }

    async getLastNMonthsofUsageBySIM(sim, months) {
        return await BaseApi.apiCallWithRefresh(BaseApi.client.get, `/v1/sim/sim_usage?sim=${sim}&months=${months}`, null, null);
    }
}

const Sims = new SimApiClass();
export default Sims;

