import LinkBuilder from "../../domain/LinkBuilder";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Lifts from "../../api/liftApi";
import HttpCodes from "../../library/HttpCodes";
import Helper from "../../library/Helper";
import {ConnectionStatus} from "../../library/connectionStatus";
import {ProductTypes} from "../../domain/SystemTypes";

import duo_logo from "../../assets/Duo-Hybrid-White.png";
import trio_logo from "../../assets/Trio-Hybrid-White.png";
import classic_logo from "../../assets/TCL-White.png";
import topDataUsageLiftsApi from "../../api/eventApi";
import moment from "moment";
import Sims from "../../api/simApi";
import SelectDropdown from "../../library/SelectDropdown";

export const SimHighDataUsageTile = ({max_rows, show_full = true, onSelectSim}) => {
    const gotopage = useNavigate() ;

    const [topDataUsageLifts, setTopDataUsageLifts] = useState([]);
    const [lastUpdated, setLastUpdated] = useState(null) ;
    const [error, setError] = useState(null) ;
    const [selected, setSelected] = useState(null) ;

    const [rowCounts, setRowCounts] = useState([
        {value: 5, label : "Top 5 data users", target: 5},
        {value: 10, label : "Top 10 data users", target: 10},
        {value: 20, label : "Top 20 data users", target: 20},
        {value: 50, label : "Top 50 data users", target: 50},
    ]) ;
    const [selectedRowCount, setSelectedRowCount] = useState({value: max_rows, label : `Top ${max_rows}`, target: max_rows}) ;

    useEffect(() => {
        let rx = rowCounts.find(r => r.target === max_rows) ;
        setSelectedRowCount(rx) ;
        refreshState(rx.target) ;
        const interval = setInterval(() => {
            refreshState(selectedRowCount?selectedRowCount.target:10) ;
        }, 60000 * 5);
        return () => clearInterval(interval);
    }, [max_rows]);

    useEffect(() => {
        console.log("Setting selected row count: ", selectedRowCount) ;
        refreshState(selectedRowCount?selectedRowCount.target:10) ;
    }, [selectedRowCount]);

    const refreshState = async (rows) => {
        try {
            console.log("ROWS = ", rows) ;
            let top = await Sims.getTopDataUsageSIMs(rows) ;
            if (top.status === HttpCodes.HttpOK) {
                setTopDataUsageLifts(top.data) ;
                setError(null) ;
            }
            else {
                setError(`Unable to refresh top ${rows} data usage lifts`)
            }

            setLastUpdated(moment()) ;
        }
        catch(e) {
            console.error(e) ;
            setError("Unable to refresh state")
        }
    }

    const selectLink = (l) => {
        console.log("Select device link: ", l) ;
        LinkBuilder.deviceView(gotopage, l.device_id, 10) ;
    }

    const selectSim = (s) => {
        console.log("Select SIM: ", s) ;
        setSelected(s);
        if (onSelectSim) {
            onSelectSim(s) ;
        }
    }

    return (
        <div style={{margin: '5px', cursor: 'pointer', verticalAlign: 'top'}}>
            <h4>High Data Usage Lifts <div className="material-icons"
                                           style={{
                                               fontSize: '16pt',
                                               marginRight: '3px',
                                               verticalAlign: '-6px',
                                               marginLeft: '30px'
                                           }}
                                           onClick={() => refreshState(selectedRowCount?.target || 5)}>refresh</div></h4>

            <div style={{fontStyle: 'italic'}}><small>Updated: {Helper.dbTimeToDisplayTime(lastUpdated)}</small></div>

            {(topDataUsageLifts.length === 0) &&
                <div style={{color: 'darkblue', fontSize: '28px'}}>Top 10 data users not available</div>
            }


            {(topDataUsageLifts.length > 0) &&
                <div>
                    <small>Data shown is for current calendar month</small>

                    <SelectDropdown
                        key={`pd_sdu`}
                        values={rowCounts}
                        selected={selectedRowCount?.value}
                        onSelectChanged={(s) => setSelectedRowCount(s)}
                        style={{marginLeft: '20px'}}
                        title="Select Number of Rows"/>

                    <table style={{backgroundColor: 'white'}}>
                        <thead>
                        <tr key={`lde_hdr`}>
                            {!show_full && <th style={{width: '30px'}}></th>}
                            <th style={{width: '200px'}}>Lift</th>
                            <th style={{width: '160px'}}>Status</th>
                            {show_full && <th style={{width: '160px'}}>Activated</th>}
                            {show_full && <th style={{width: '160px'}}>Last SIM Update</th>}
                            <th style={{width: '120px', textAlign: 'center'}}>Usage (bytes)</th>
                        </tr>
                        </thead>
                        <tbody>

                        {(topDataUsageLifts.map((e, ex) => {
                            let row_color = 'white';

                            if (show_full && selected === e.sim_number) {
                                row_color = '#c3fac6';
                            } else if (e.last_data_usage > 50_000_000) {
                                row_color = '#fcd0bf';
                            } else if (e.last_data_usage > 35_000_000) {
                                row_color = '#ffea5e';
                            }

                            return (
                                <tr key={`lde_${ex}`} style={{backgroundColor: `${row_color}`}}>
                                    {!show_full &&
                                        <td>
                                            {e.device_id !== null && <div className="image-button"><span className="material-icons" title="View Data"
                                                                                style={{
                                                                                    verticalAlign: '0px',
                                                                                    fontSize: '16px'
                                                                                }}
                                                                                onClick={() => selectLink(e)}>query_stats</span>
                                            </div>}
                                        </td>
                                    }
                                    <td style={{fontSize: '12px'}} onClick={() => show_full  ? selectSim(e.sim_number) : {}}>
                                        <div style={{fontSize: '14px', paddingBottom: '8px'}}>{e.sim_number || "- no name -"}</div>
                                        <div>SIM Name: <i>{e.friendly_name || "- no name -"}</i></div>
                                        <div >Lift Name: <i>{e.device_id ? (e.display_name || "- no name -") : "- not associated with lift -"}</i></div>
                                    </td>


                                    {e.status === "suspended" && <td style={{fontSize: '14px', color: 'red'}}>Suspended - Needs Attention</td>}
                                    {e.status !== "suspended" && <td style={{fontSize: '12px'}}>{e.status || "- unknown -"}</td>}
                                    {show_full && <td style={{fontSize: '12px'}}>{Helper.dbTimeToDisplayTime(e.sim_activation_ts)}</td>}
                                    {show_full && <td style={{fontSize: '12px'}}>{Helper.dbTimeToDisplayTime(e.last_data_usage_ts)}</td>}

                                    <td style={{
                                        fontSize: '12px',
                                        textAlign: 'center'
                                    }}>{Helper.asNumberString(e.last_data_usage)}</td>
                                </tr>
                            )
                        }))
                        }
                        </tbody>
                    </table>
                </div>
            }


        </div>
    )
}