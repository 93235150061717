import {SimProviderStatusCountsTile} from "./SimProviderStatusCountsTile";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../App";
import {useContext, useEffect, useState} from "react";
import Sims from "../../api/simApi";
import HttpCodes from "../../library/HttpCodes";
import moment from "moment/moment";
import Helper from "../../library/Helper";
import SimStatusList from "./SimStatusList";


export const SimManagementPanel = () => {
    const gotopage = useNavigate() ;
    const authorisation = useContext(AuthContext)

    const [statusCounts, setStatusCounts] = useState([]);
    const [lastUpdated, setLastUpdated] = useState(null) ;
    const [error, setError] = useState(null) ;
    const [selectedRow, setSelectedRow] = useState(null) ;

    const [selectedSimProviderState, setSelectedSimProviderState] = useState(null) ;
    const [sims, setSims] = useState([]) ;

    useEffect(() => {
        refreshState() ;
    }, []);

    useEffect(async () => {
        if (selectedRow !== null) {
            console.log("Selected row: ", selectedRow, statusCounts[selectedRow]) ;
            setSelectedSimProviderState(statusCounts[selectedRow]) ;
            await refreshSimSet(statusCounts[selectedRow].status) ;
        }
        else {
            setSelectedSimProviderState(null) ;
            setSims([]) ;
        }
    }, [selectedRow]);

    const refreshState = async () => {
        try {
            let counts = await Sims.getSIMStatusCounts() ;
            if (counts.status === HttpCodes.HttpOK) {
                setStatusCounts(counts.data) ;
                setError(null) ;
            }
            else {
                setError("Unable to refresh status counts")
            }

            setLastUpdated(moment()) ;
        }
        catch(e) {
            console.error(e) ;
            setError("Unable to refresh state")
        }
        finally {
            setSelectedRow(null) ;
        }
    }

    const refreshSimSet = async (status) => {
        try {
            let simdata = await Sims.getSIMsOfStatus(status)
            if (simdata.status === HttpCodes.HttpOK) {
                setSims(simdata.data) ;
                setError(null) ;
            }
            else {
                setError("Unable to refresh status counts")
            }

        }
        catch(e) {
            console.error(e) ;
            setError("Unable to refresh state")
            setSims([]) ;
        }
    }


    return (
        <div>
            <div>
                <button onClick={() => {
                }}>
                        <span className="material-icons" style={{
                            verticalAlign: '-6px',
                            paddingRight: '8px'
                        }}>publish</span>Import Production Allocations
                </button>
            </div>
            <br/>

            <div style={{verticalAlign: 'top', flex: '1'}}>
                <div style={{margin: '5px', cursor: 'pointer', verticalAlign: 'top'}}>
                    <div className="section-header">SIM Status Counts <div className="material-icons"
                                                                           style={{
                                                                               fontSize: '16pt',
                                                                               marginRight: '3px',
                                                                               verticalAlign: '-6px',
                                                                               marginLeft: '30px'
                                                                           }}
                                                                           onClick={refreshState}>refresh</div></div>

                    <div style={{fontStyle: 'italic'}}><small>Updated: {Helper.dbTimeToDisplayTime(lastUpdated)}</small>
                    </div>

                    {(statusCounts.length > 0) &&
                        <div>
                            {/*I want to display the status counts as individual 'pill' items with the status and the count highlighted*/}
                            <table style={{backgroundColor: 'white'}}>
                                <thead>
                                <tr key={`lde_hdr`}>
                                    <th style={{width: '200px'}}>Provider</th>
                                    <th style={{width: '200px'}}>Status</th>
                                    <th style={{width: '120px', textAlign: 'center'}}>SIM Count</th>
                                </tr>
                                </thead>
                                <tbody>

                                {(statusCounts.map((e, ex) => {
                                    let row_color = 'white';

                                    if (ex === selectedRow) {
                                        row_color = '#c3fac6';
                                    }

                                    return (
                                        <tr key={`lde_${ex}`} style={{backgroundColor: `${row_color}`}}
                                            onClick={() => setSelectedRow(ex)}>
                                            <td style={{fontSize: '12px'}}><b style={{color: 'darkred'}}>{e.origin}</b>
                                            </td>
                                            <td style={{fontSize: '12px'}}>
                                                <b style={{color: 'darkred'}}>{e.status || "- no status -"}</b></td>
                                            <td style={{
                                                fontSize: '12px',
                                                textAlign: 'center'
                                            }}>{Helper.asNumberString(e.status_count)}</td>
                                        </tr>
                                    )
                                }))
                                }
                                </tbody>
                            </table>
                        </div>
                    }

                    {(statusCounts.length === 0) &&
                        <div style={{color: 'darkblue', fontSize: '28px'}}>SIM status counts not available</div>
                    }

                </div>
            </div>


            <div>
            <div className="section-header">Selected SIMs</div>
                <SimStatusList sims={sims} onSelect={(sim) => console.log("Selected SIM: ", sim)} show_len={30}/>
            </div>
        </div>
    )
}