import {SimHighDataUsageTile} from "./SimHighDataUsageTile";
import LiftDeviceSimDataUsage from "./LiftDeviceSimDataUsage";
import {useState} from "react";


export const SimDataMonitoringPanel = () => {
    const [selectedSim, setSelectedSim] = useState(null) ;

    return (
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
            <div style={{verticalAlign: 'top', maxWidth: '45%', flex: '1'}}>
                <SimHighDataUsageTile max_rows={10} onSelectSim={setSelectedSim}/>
            </div>

            {selectedSim &&
                <div style={{maxWidth: '50%', flex: '1'}}>
                    <LiftDeviceSimDataUsage sim_number={selectedSim} sim_data_limit={50_000_000}/>
                </div>
            }
        </div>
    )
}