
import {IOBitsA, IOBitsB, IOBitsC} from "../domain/DataPointHelpers";


const _ruleStore = {
    binary_inputs : [],
    analogue_inputs : []
}

class RuleStoreClass {

    getBinaryInputs = (force = false) => {
        if (!force && _ruleStore.binary_inputs.length > 0) {
            return _ruleStore.binary_inputs ;
        }

        let inputs = [];
        for (let k of Object.keys(IOBitsA)) {
            let i = IOBitsA[k] ;
            let t = {
                rule_set_item_id : null,
                bit : i.bit ,
                set : i.set,
                trip : i.tripLevel,
                condition_op_id : 2 // is ON
            }
            inputs.push({value: `${i.set}_${i.bit}`, display: i.desc, target: t}) ;
        }

        for (let k of Object.keys(IOBitsB)) {
            let i = IOBitsB[k] ;
            let t = {
                rule_set_item_id : null,
                bit : i.bit ,
                set : i.set,
                trip : i.tripLevel,
                condition_op_id : 1 // is ON
            }
            inputs.push({value: `${i.set}_${i.bit}`, display: i.desc, target: t}) ;
        }

        for (let k of Object.keys(IOBitsC)) {
            let i = IOBitsC[k] ;
            let t = {
                rule_set_item_id : null,
                bit : i.bit ,
                set : i.set,
                trip : i.tripLevel,
                condition_op_id : 1 // is ON
            }
            inputs.push({value: `${i.set}_${i.bit}`, display: i.desc, target: t}) ;
        }

        _ruleStore.binary_inputs = inputs ;
        return _ruleStore.binary_inputs ;
    }
    getAnalogueInputs = (force = false) => {
        if (!force && _ruleStore.analogue_inputs.length > 0) {
            return _ruleStore.analogue_inputs ;
        }

        let analogue = [
            {value: "v_bat_voltage_mv", display: "Battery Voltage", target: {field: 'v_bat_voltage_mv'}},
            {value: "l_lift_angle", display: "Lift Angle", target: {field: 'l_lift_angle'}},
            {value: "a_m1_current_up", display: "M1 Current UP", target: {field: 'a_m1_current_up'}},
            {value: "a_m2_current_up", display: "M2 Current UP", target: {field: 'a_m2_current_up'}},
            {value: "q_m1_current_down", display: "M1 Current Down", target: {field: 'q_m1_current_down'}},
            {value: "q_m2_current_down", display: "M2 Current Down", target: {field: 'q_m2_current_down'}},
            {value: "e_motor1_enc_count", display: "M1 Encoder Count", target: {field: 'e_motor1_enc_count'}},
            {value: "e_motor2_enc_count", display: "M2 Encoder Count", target: {field: 'e_motor2_enc_count'}},
            {value: "k_cur_pot_read", display: "Current Weight Reading", target: {field: 'k_cur_pot_read'}},
            {value: "h_charger_status", display: "Charger Status", target: {field: 'h_charger_status'}},
        ] ;

        _ruleStore.analogue_inputs = analogue ;
        return _ruleStore.analogue_inputs ;
    }
}

const RuleStore = new RuleStoreClass();
export default RuleStore ;

