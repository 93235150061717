import {useEffect, useRef, useState} from "react";




export const RefreshableFilterDropdown = ({list, refresher, selected, onSelect, placeholder, loadTitle, onClose, width}) => {

    const [filterText, setFilterText] = useState("");
    const [sourceList, setSourceList] = useState([]) ;
    const [displayList, setDisplayList] = useState([]) ;
    const [open, setOpen] = useState(false) ;
    const [dataLoaded, setDataLoaded] = useState(false) ;

    const [listExpanded, setListExpanded] = useState(false) ;
    const [selectedItem, setSelectedItem] = useState(selected) ;
    const inputRef = useRef(null);

    useEffect(() => {
        setSourceList(list || []) ;
        filter(list || [], filterText) ;
        if (list && list.length > 0) {
            setDataLoaded(true) ;
        }
    }, [list]);

    useEffect(() => {
        console.log("Dropdown: selcted=", selected) ;
        setSelectedItem(selected) ;
    }, [selected]);

    const focus = () => {
        setOpen(true) ;
        if (!dataLoaded) {
            setTimeout(() => {
                load();
            }, 500);
        }
    }

    const blur = () => {
        setTimeout(() => {
            setOpen(false);
            onClose() ;
        }, 500);
    }

    const load = async () => {
        if (!refresher) {
            return ;
        }

        try {
            let data = await refresher() ;
            setSourceList(data || []) ;
            filter(data || [], filterText) ;
            setDataLoaded(true) ;
        }
        catch(e) {
            console.error("Problem loading data : ", e.message) ;
        }
    }

    const filter = (list, t) => {
        setFilterText(t) ;
        if ("" === t) {
            setDisplayList(list) ;
            setSelectedItem(null) ;
            // onSelect(null);
        }
        else {
            let mt = t.toUpperCase() ;
            let dp = list.filter(e => e.display && e.display.toUpperCase().includes(mt)) ;
            setDisplayList(dp) ;
        }
    }

    const select = (i) => {
        if (i) {
            setFilterText(i.display) ;
            setSelectedItem(i) ;
            setListExpanded(false) ;
        }
        onSelect(i) ;
        setOpen(false);
    }

    const expandList =  (e) => {
        setListExpanded(e) ;
        if (e) {
            setTimeout(async () => {
                inputRef.current.focus() ;
            }, 250);
        }
    }


    return (
        <div style={{width: `${width}px`}}>

            <div style={{borderBottom: '1px solid darkgray', padding: '5px'}} onClick={() => expandList(!listExpanded)}>
                <span style={{color: `${selectedItem ? "darkblue" : "darkgray"}`}}>{selectedItem ? `${selectedItem.display}` : placeholder}</span>
                <span className="material-icons" style={{float: 'right', cursor: 'pointer'}}  >{listExpanded ? "arrow_drop_up" : "arrow_drop_down"}</span>
            </div>

            {(listExpanded) &&
                <div className="dropdown-box">
                    <div style={{borderBottom: '1px solid red', padding: '2px', width: `${width}px`}}>
                        <input
                            type="text"
                            ref={inputRef}
                            style={{textDecoration: 'none', border: 'none', outline: 'none', width: width}}
                            onFocus={() => {}}
                            value={filterText}
                            onChange={(e) => filter(sourceList, e.target.value)} />
                        <span className="material-icons" style={{float: 'right', cursor: 'pointer', fontSize: 'large'}} onClick={() => filter(sourceList, "")} >close</span>
                    </div>
                    {(dataLoaded && displayList.length > 0) &&
                        <div className="scrollable-div" style={{maxHeight: '150px', width: width}}>
                            {displayList.map((i,ix) => {
                                let s = (selectedItem && selectedItem.display === i.display) ? "dropdown-filter-item dropdown-filter-item-selected" : "dropdown-filter-item" ;
                                return (
                                    <div className={s} onClick={() => select(i)}>{i.display}</div>
                                )
                            })}
                        </div>
                    }
                </div>
            }

        </div>
    )
}
