
import {F301Lookup} from "./F301Lookup";
import {L475Lookup} from "./L475Lookup";


export const TimelineDataMapper = (source) => {
    // Map from the status date format to the timeline data format
    let result = {
        device_id : null,
        safeties : {},
        num_data_points: 0,
        earliest_data: null,
        latest_data : null,
        ts : [],
        safety : [],
        up_safety : [],
        down_safety: [],
        v_bat_voltage_mv : [],
        l_lift_angle : [],
        a_m1_current_up : [],
        a_m2_current_up : [],
        q_m1_current_down : [],
        q_m2_current_down : [],
        e_motor1_enc_count : [],
        e_motor2_enc_count : [],
        k_cur_pot_read : [],
        h_charger_status : [],
        f301 : [],
        l475 : [],
        aa_level_error : [],
        aa_slide_error : []
    }
    // console.log("Timeline Data Mapper: ", source) ;

    if (!(source?.dataset?.data)) {
        return result ;
    }

    for (let a of Object.values(source.adc_a_map)) {
        result[`${a.adc}_${a.bit}`] = [] ;
    }

    for (let b of Object.values(source.adc_b_map)) {
        result[`${b.adc}_${b.bit}`] = [] ;
    }

    for (let c of Object.values(source.adc_c_map)) {
        result[`${c.adc}_${c.bit}`] = [] ;
    }

    // for (let i = 0 ; i < 16 ; i++) {
    //     result[`O_${i}`] = [] ;
    // }

    result.device_id = source.dataset.device_id ;
    result.earliest_data = source.dataset.earliest_available ;
    result.latest_data = source.dataset.latest_available ;
    result.num_data_points = source.dataset.data.length ;

    for (let d of source.dataset.data) {
        // build list of timestamps
        result.ts.push(d.received_at) ;

        result.v_bat_voltage_mv.push(d.v_bat_voltage_mv) ;
        result.l_lift_angle.push(d.l_lift_angle) ;
        result.a_m1_current_up.push(d.a_m1_current_up) ;
        result.a_m2_current_up.push(d.a_m2_current_up) ;
        result.q_m1_current_down.push(d.q_m1_current_down) ;
        result.q_m2_current_down.push(d.q_m2_current_down) ;
        result.e_motor1_enc_count.push(d.e_motor1_enc_count) ;
        result.e_motor2_enc_count.push(d.e_motor2_enc_count) ;
        result.k_cur_pot_read.push(d.k_cur_pot_read) ;
        result.h_charger_status.push(d.h_charger_status) ;

        for (let a of Object.values(source.adc_a_map)) {
            let tripped = (d.adc_bits_a[a.bit - 1] === a.trip) ;
            result[`${a.adc}_${a.bit}`].push(tripped);
        }

        for (let b of Object.values(source.adc_b_map)) {
            let tripped = (d.adc_bits_b[b.bit - 1] === b.trip) ;
            result[`${b.adc}_${b.bit}`].push(tripped);
        }

        for (let c of Object.values(source.adc_c_map)) {
            let tripped = (d.adc_bits_c[c.bit - 1] === c.trip) ;
            result[`${c.adc}_${c.bit}`].push(tripped);
        }

        // for (let i = 0 ; i < 16 ; i++) {
        //     let bitSet = (d.o_outputs & (1 << i)) !== 0;
        //     result[`O_${i}`].push(bitSet);
        // }

        result.f301.push(d.s_f301_sr || F301Lookup.byLabel.LOG_NONE) ;
        result.l475.push(d.s_f475_sr || L475Lookup.byLabel.LOG_NONE) ;

        result.aa_level_error.push(d.aa_rs485_level_board_error || 0) ;
        result.aa_slide_error.push(d.aa_rs485_slide_board_error || 0) ;
    }

    // console.log("TimelineDataMapper result = ", result) ;
    return result ;
}