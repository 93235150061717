/*
 * Copyright 2023 Stiltz Ltd. All Rights Reserved.
 *
 */
import React, {useEffect, useState} from 'react';
import ButtonGlyph from "./buttonGlyph";


const ModelConfirmView = ({open, title, message, onCancel, onConfirm}) => {

    const [isOpen, setIsOpen] = useState(false) ;

    useEffect(() => {
        setIsOpen(open) ;
    }, [open, title, message, onCancel, onConfirm]);

    // console.log("Modal Confirm open - ", open, isOpen) ;

    if (!isOpen) {
        return <></>
    }

    return (
        <div className="dialog-bg" >
            <dialog open={isOpen} style={{marginTop: '100px'}}>
                <div >
                    <div className="dialog-title">{title}</div>
                    <br/>
                    <div className="dialog-text"><p>{message}</p></div>
                    <br/>
                    <div>
                        <button className="base-button search-button" style={{float: 'left'}} onClick={onCancel}><ButtonGlyph glyph="cancel" style={{marginRight: '10px', verticalAlign: '-4px'}}/>Cancel</button>
                        <button className="base-button clear-button" style={{float: 'right'}} onClick={onConfirm}><ButtonGlyph glyph="delete" style={{marginRight: '10px', verticalAlign: '-4px'}}/>Confirm</button>
                    </div>
                </div>
            </dialog>
        </div>

    );
}

export default ModelConfirmView ;
