import moment from "moment";
import HttpCodes from "../library/HttpCodes";
import IOApiDefinition from "../api/ioApi";


const _ioStore = {
    timestamp : null,
    loading : false,
    io : null
}

class IODefinitionStoreClass {

    io = async (forceRefresh = false) => {
        if (_ioStore.loading) {
            return _ioStore.io ;
        }

        if (!forceRefresh && _ioStore.io) {
            return _ioStore.io ;
        }

        try {
            _ioStore.loading = true ;
            let result = await IOApiDefinition.getIODefinitions()
            if (result.status === HttpCodes.HttpOK) {
                _ioStore.io = result.data ;
                _ioStore.timestamp = moment.utc().format();
            }
        }
        catch(e) {
            console.error("Problem loading IO Definitions - ", e.message);
        }
        finally {
            _ioStore.loading = false ;
        }

        return _ioStore.io ;
    }

    revisionIO = async (revisionNumber, forceRefresh = true) => {
        let io = await this.io(forceRefresh) ;
        if (!io) {
            return null ;
        }

        let dio = {
            def        : io,        // full IO definition set
            target_rev : null,      // target revision
            target_io  : null,      // target IO definition
        }
        dio.target_rev = io.io_rev_map[revisionNumber] || io.io_rev_map["*"]
        if (dio.target_rev) {
            dio.target_io = io.io_sets[dio.target_rev.io]
        }

        return dio ;
    }
}

const IODefinitionStore = new IODefinitionStoreClass();
export default IODefinitionStore ;

