import duo_logo from "../../assets/Duo-Hybrid-Logo-FC.jpg";
import trio_logo from "../../assets/Trio-Hybrid-Logo-FC.jpg";
import {ProductTypes} from "../../domain/SystemTypes";


export const LiftProductTypeLogo = ({product_type_id}) => {
    if (product_type_id === ProductTypes.Duo) {
        return <img className="logo" style={{width: '150px', verticalAlign: '-20px', marginLeft: '30px', marginRight: '30px'}} src={duo_logo} alt="Stiltz - Product" />
    }
    else {
        return <img className="logo" style={{width: '150px', verticalAlign: '-20px', marginLeft: '30px', marginRight: '30px'}} src={trio_logo} alt="Stiltz - Product" />
    }
}