import {useEffect, useState} from "react";


export const LiftPLCDataView = ({device, datapoint}) => {

    const [line1, setLine1] = useState([1,1,2,3,4,5,6,7,8,9,0,1,2]) ;
    const [line2, setLine2] = useState([2,1,2,3,4,5,6,7,8,9,0,1,2]) ;
    const [line3, setLine3] = useState([3,1,2,3,4,5,6,7,8,9,0,1,2]) ;
    const [line4, setLine4] = useState([4,1,2,3,4,5,6,7,8,9,0,1,2]) ;

    useEffect(() => {
        if (datapoint) {
            console.log("Datapoint = ", datapoint) ;
            setLine1(datapoint.display_line_1) ;
            setLine2(datapoint.display_line_2) ;
            setLine3(datapoint.display_line_3) ;
            setLine4(datapoint.display_line_4) ;
        }
    }, [device, datapoint]);


    return (
        <div>
            <h5>PLC Data View</h5>

            <div  style={{display: 'flex', flexWrap: 'wrap', alignContent: 'space-around'}} >
                <div className="plc-container">
                    <div className="plc-row">
                        <div id="r1c1" className="plc-cell" >{String.fromCharCode(line1[0])}</div>
                        <div id="r1c2" className="plc-cell" >{String.fromCharCode(line1[1])}</div>
                        <div id="r1c3" className="plc-cell" >{String.fromCharCode(line1[2])}</div>
                        <div id="r1c4" className="plc-cell" >{String.fromCharCode(line1[3])}</div>
                        <div id="r1c5" className="plc-cell" >{String.fromCharCode(line1[4])}</div>
                        <div id="r1c6" className="plc-cell" >{String.fromCharCode(line1[5])}</div>
                        <div id="r1c7" className="plc-cell" >{String.fromCharCode(line1[6])}</div>
                        <div id="r1c8" className="plc-cell" >{String.fromCharCode(line1[7])}</div>
                        <div id="r1c9" className="plc-cell" >{String.fromCharCode(line1[8])}</div>
                        <div id="r1c10" className="plc-cell">{String.fromCharCode(line1[9])}</div>
                        <div id="r1c11" className="plc-cell">{String.fromCharCode(line1[10])}</div>
                        <div id="r1c12" className="plc-cell">{String.fromCharCode(line1[11])}</div>
                    </div>
                    <div className="plc-row">
                        <div id="r2c1"  className="plc-cell">{String.fromCharCode(line2[0])}</div>
                        <div id="r2c2"  className="plc-cell">{String.fromCharCode(line2[1])}</div>
                        <div id="r2c3"  className="plc-cell">{String.fromCharCode(line2[2])}</div>
                        <div id="r2c4"  className="plc-cell">{String.fromCharCode(line2[3])}</div>
                        <div id="r2c5"  className="plc-cell">{String.fromCharCode(line2[4])}</div>
                        <div id="r2c6"  className="plc-cell">{String.fromCharCode(line2[5])}</div>
                        <div id="r2c7"  className="plc-cell">{String.fromCharCode(line2[6])}</div>
                        <div id="r2c8"  className="plc-cell">{String.fromCharCode(line2[7])}</div>
                        <div id="r2c9"  className="plc-cell">{String.fromCharCode(line2[8])}</div>
                        <div id="r2c10" className="plc-cell">{String.fromCharCode(line2[9])}</div>
                        <div id="r2c11" className="plc-cell">{String.fromCharCode(line2[10])}</div>
                        <div id="r2c12" className="plc-cell">{String.fromCharCode(line2[11])}</div>
                    </div>
                    <div className="plc-row">
                        <div id="r3c1"  className="plc-cell">{String.fromCharCode(line3[0])}</div>
                        <div id="r3c2"  className="plc-cell">{String.fromCharCode(line3[1])}</div>
                        <div id="r3c3"  className="plc-cell">{String.fromCharCode(line3[2])}</div>
                        <div id="r3c4"  className="plc-cell">{String.fromCharCode(line3[3])}</div>
                        <div id="r3c5"  className="plc-cell">{String.fromCharCode(line3[4])}</div>
                        <div id="r3c6"  className="plc-cell">{String.fromCharCode(line3[5])}</div>
                        <div id="r3c7"  className="plc-cell">{String.fromCharCode(line3[6])}</div>
                        <div id="r3c8"  className="plc-cell">{String.fromCharCode(line3[7])}</div>
                        <div id="r3c9"  className="plc-cell">{String.fromCharCode(line3[8])}</div>
                        <div id="r3c10" className="plc-cell">{String.fromCharCode(line3[9])}</div>
                        <div id="r3c11" className="plc-cell">{String.fromCharCode(line3[10])}</div>
                        <div id="r3c12" className="plc-cell">{String.fromCharCode(line3[11])}</div>
                    </div>
                    <div className="plc-row">
                        <div id="r4c1"  className="plc-cell">{String.fromCharCode(line4[0])}</div>
                        <div id="r4c2"  className="plc-cell">{String.fromCharCode(line4[1])}</div>
                        <div id="r4c3"  className="plc-cell">{String.fromCharCode(line4[2])}</div>
                        <div id="r4c4"  className="plc-cell">{String.fromCharCode(line4[3])}</div>
                        <div id="r4c5"  className="plc-cell">{String.fromCharCode(line4[4])}</div>
                        <div id="r4c6"  className="plc-cell">{String.fromCharCode(line4[5])}</div>
                        <div id="r4c7"  className="plc-cell">{String.fromCharCode(line4[6])}</div>
                        <div id="r4c8"  className="plc-cell">{String.fromCharCode(line4[7])}</div>
                        <div id="r4c9"  className="plc-cell">{String.fromCharCode(line4[8])}</div>
                        <div id="r4c10" className="plc-cell">{String.fromCharCode(line4[9])}</div>
                        <div id="r4c11" className="plc-cell">{String.fromCharCode(line4[10])}</div>
                        <div id="r4c12" className="plc-cell">{String.fromCharCode(line4[11])}</div>
                    </div>
                </div>
            </div>

        </div>
    )
}