
export const TripBits = [
    {field : 'bit_bottom_limit1', bit : 1,  tripLevel: '0', is_safety: true, set : 'a', desc: 'Bottom Limit 1'},
    {field : 'bit_roller_input3', bit : 2,  tripLevel: '1', is_safety: false, set : 'a', desc: 'Roller Input 3'},
    {field : 'bit_roller_input2', bit : 3,  tripLevel: '1', is_safety: false, set : 'a', desc: 'Roller Input 2'},
    {field : 'bit_prly_power_detail', bit : 4,  tripLevel: '0', is_safety: false, set : 'a', desc: 'Prly Power Detail'},
    {field : 'bit_bottom_limit2', bit : 5,  tripLevel: '0', is_safety: true, set : 'a', desc: 'Bottom Limit 2'},
    {field : 'bit_landing_lock_lss', bit : 6,  tripLevel: '0', is_safety: true, set : 'a', desc: 'Landing Lock LSS'},
    {field : 'bit_balance1', bit : 7,  tripLevel: '0', is_safety: true, set : 'a', desc: 'Balance 1'},
    {field : 'bit_drly_power_detail', bit : 8,  tripLevel: '0', is_safety: false, set : 'a', desc: 'Drly Power Detail'},
    {field : 'bit_bottom_limit3', bit : 9,  tripLevel: '0', is_safety: true, set : 'a', desc: 'Bottom Limit 3'},
    {field : 'bit_light_guard_front', bit : 10, tripLevel: '0', is_safety: true, set : 'a', desc: 'Light Guard Front'},
    {field : 'bit_top_obstacle1', bit : 11, tripLevel: '0', is_safety: true, set : 'a', desc: 'Top Obstacle 1'},
    {field : 'bit_urly_power_detail', bit : 12, tripLevel: '0', is_safety: false, set : 'a', desc: 'Urly Power Detail'},
    {field : 'bit_bottom_final_limit', bit : 13, tripLevel: '0', is_safety: true, set : 'a', desc: 'Bottom Final Limit'},
    {field : 'bit_spare2', bit : 14, tripLevel: '0', is_safety: false, set : 'a', desc: 'Spare 2'},
    {field : 'bit_roller_input1', bit : 15, tripLevel: '1', is_safety: false, set : 'a', desc: 'Roller Input 1'},
    {field : 'bit_remote_toggle_down', bit : 16, tripLevel: '1', is_safety: false, set : 'a', desc: 'Remote Toggle Down'},
    {field : 'bit_keyswitch', bit : 17, tripLevel: '0', is_safety: true, set : 'a', desc: 'Key Switch'},
    {field : 'bit_tamper_switch_hz', bit : 18, tripLevel: '0', is_safety: true, set : 'a', desc: 'Tamper Switch HZ'},
    {field : 'bit_light_guard_rear', bit : 19, tripLevel: '0', is_safety: true, set : 'a', desc: 'Light Guard Rear'},
    {field : 'bit_top_obstacle2', bit : 20, tripLevel: '0', is_safety: true, set : 'a', desc: 'Top Obstacle 2'},
    {field : 'bit_cop_down', bit : 21, tripLevel: '1', is_safety: false, set : 'a', desc: 'COP Down'},
    {field : 'bit_charger_connected', bit : 22, tripLevel: '0', is_safety: false, set : 'a', desc: 'Charger Connected'},
    {field : 'bit_top_final_limit', bit : 23, tripLevel: '0', is_safety: true, set : 'a', desc: 'Top Final Limit'},
    {field : 'bit_remote_toggle_up', bit : 24, tripLevel: '1', is_safety: false, set : 'a', desc: 'Remote Toggle Up'},
    {field : 'bit_cop_up', bit : 25, tripLevel: '1', is_safety: false, set : 'a', desc: 'COP Up'},
    {field : 'bit_emergency_stop', bit : 26, tripLevel: '0', is_safety: true, set : 'a', desc: 'Emergency Stop'},
    {field : 'bit_overload', bit : 27, tripLevel: '0', is_safety: true, set : 'a', desc: 'Overload'},
    {field : 'bit_top_obstacle3', bit : 28, tripLevel: '0', is_safety: true, set : 'a', desc: 'Top Obstacle 3'},
    {field : 'bit_cop_mid', bit : 29, tripLevel: '1', is_safety: false, set : 'a', desc: 'COP Mid'},
    {field : 'bit_landing_closed_dss', bit : 30, tripLevel: '0', is_safety: true, set : 'a', desc: 'Landing Closed DSS'},
    {field : 'bit_balance2', bit : 31, tripLevel: '0', is_safety: true, set : 'a', desc: 'Balance 2'},
    {field : 'bit_top_obstacle4', bit : 32, tripLevel: '0', is_safety: true, set : 'a', desc: 'Top Obstacle 4'},
    {field: 'bit_bottom_limit4', bit: 1, tripLevel: '0', is_safety : true, set: 'b', desc: 'Bottom Limit 4'},
    {field: 'bit_bottom_limit5', bit: 2, tripLevel: '0', is_safety : true, set: 'b', desc: 'Bottom Limit 5'},
    {field: 'bit_bottom_limit6', bit: 3, tripLevel: '0', is_safety : true, set: 'b', desc: 'Bottom Limit 6'},
    {field: 'bit_bottom_limit7', bit: 4, tripLevel: '0', is_safety : true, set: 'b', desc: 'Bottom Limit 7'}
];
