import jwtDecode from "jwt-decode";



const getAuthorisation = (auth) => {
    let isAdmin = false
    let isSupport = false
    let isDealer = false
    let status = "INVALID"

    if (auth) {
        // console.log("Authorisation from: ", auth);
        const decoded = jwtDecode(auth)
        if (decoded?.account.userid) {
            const {userid, emailaddress, usertype} = decoded.account

            isAdmin = "ADMIN" === usertype
            isSupport = "SUPPORT" === usertype
            isDealer = "INSTALLER" === usertype

            if (isDealer) status = "DEALER"
            if (isSupport) status = "SUPPORT"
            if (isAdmin) status = "ADMIN"

            return {
                account : decoded.account,
                isAdmin,
                isSupport ,
                isDealer ,
                status,
                authorised : true
            }
        }
    }

    return {
        account : null,
        isAdmin,
        isSupport,
        isDealer,
        status,
        authorised : false
    }
}

export default getAuthorisation