

export const ruleStatusBarState = (r) => {
    switch (r.rule_event_type_id) {
        case 1 : return "good" ;
        case 2 : return "error" ;
        case 3 : return "warn" ;
        case 4 : return "action" ;
        default: return "error" ;
    }
};



