import LinkBuilder from "../../domain/LinkBuilder";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Lifts from "../../api/liftApi";
import HttpCodes from "../../library/HttpCodes";
import Helper from "../../library/Helper";
import {ConnectionStatus} from "../../library/connectionStatus";
import {ProductTypes} from "../../domain/SystemTypes";

import duo_logo from "../../assets/Duo-Hybrid-White.png";
import trio_logo from "../../assets/Trio-Hybrid-White.png";
import classic_logo from "../../assets/TCL-White.png";
import topDataUsageLiftsApi from "../../api/eventApi";
import moment from "moment";
import Sims from "../../api/simApi";

export const SimProviderStatusCountsTile = () => {
    const gotopage = useNavigate() ;

    const [statusCounts, setStatusCounts] = useState([]);
    const [lastUpdated, setLastUpdated] = useState(null) ;
    const [error, setError] = useState(null) ;

    useEffect(() => {
        refreshState() ;
        const interval = setInterval(() => {
            refreshState() ;
        }, 60000 * 5);
        return () => clearInterval(interval);
    }, []);

    const refreshState = async () => {
        try {
            let counts = await Sims.getSIMStatusCounts() ;
            if (counts.status === HttpCodes.HttpOK) {
                setStatusCounts(counts.data) ;
                setError(null) ;
            }
            else {
                setError("Unable to refresh status counts")
            }

            setLastUpdated(moment()) ;
        }
        catch(e) {
            console.error(e) ;
            setError("Unable to refresh state")
        }
    }


    return (
        <div style={{margin: '5px', cursor: 'pointer', verticalAlign: 'top'}}>
            <h4>SIM Status Counts <div className="material-icons"
                                           style={{
                                               fontSize: '16pt',
                                               marginRight: '3px',
                                               verticalAlign: '-6px',
                                               marginLeft: '30px'
                                           }}
                                           onClick={refreshState}>refresh</div></h4>

            <div style={{fontStyle: 'italic'}}><small>Updated: {Helper.dbTimeToDisplayTime(lastUpdated)}</small></div>

            {(statusCounts.length > 0) &&
                <div>
                    {/*I want to display the status counts as individual 'pill' items with the status and the count highlighted*/}
                    <table style={{backgroundColor: 'white'}}>
                        <thead>
                        <tr key={`lde_hdr`}>
                            <th style={{width: '200px'}}>Provider</th>
                            <th style={{width: '200px'}}>Status</th>
                            <th style={{width: '120px', textAlign: 'center'}}>SIM Count</th>
                        </tr>
                        </thead>
                        <tbody>

                        {(statusCounts.map((e, ex) => {
                            let row_color = 'white';

                            if (e.status === "suspended") {
                                row_color = '#fcd0bf';
                            } else if (e.status === "provisioned") {
                                row_color = '#c3fac6';
                            }

                            return (
                                <tr key={`lde_${ex}`} style={{backgroundColor: `${row_color}`}}>
                                    <td style={{fontSize: '12px'}}><b style={{color: 'darkred'}}>{e.origin}</b></td>
                                    <td style={{fontSize: '12px'}}>
                                        <b style={{color: 'darkred'}}>{e.status || "- no status -"}</b></td>
                                    <td style={{
                                        fontSize: '12px',
                                        textAlign: 'center'
                                    }}>{Helper.asNumberString(e.status_count)}</td>
                                </tr>
                            )
                        }))
                        }
                        </tbody>
                    </table>
                </div>
            }

            {(statusCounts.length === 0) &&
                <div style={{color: 'darkblue', fontSize: '28px'}}>SIM status counts not available</div>
            }

        </div>
    )
}