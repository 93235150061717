import {TriggerType} from "../views/triggers/TriggerDashboard";
import LocalState from "../library/LocalState";


class LinkBuilderClass {

    addQuickLink(link) {
        console.log("[LinkBuilder] - build quick link : ", link) ;
        let quickLinks = LocalState.get(LocalState.Keys.QuickLink) ;
        if (!quickLinks) {
            quickLinks = {} ;
        }

        let exists = quickLinks[link.key] ;
        if (!exists) {
            quickLinks[link.key] = link ;
            LocalState.put(LocalState.Keys.QuickLink, quickLinks, true) ;
        }
    }

    removeQuickLink(link_key) {
        let links = LocalState.get(LocalState.Keys.QuickLink) ;
        if (links) {
            delete links[link_key] ;
            LocalState.put(LocalState.Keys.QuickLink, links, true) ;
        }
    }

    gotoLink(navigator, link) {
        // console.log("GOTO LINK: ", link) ;
        if (!link) {
            return ;
        }

        switch (link.link_type) {
            case "LIFT" : this.deviceView(navigator, link.device_id, 1) ; break ;
            case "CUST" : this.customer(navigator, link.customer_id) ; break ;
            default: break ;
        }
    }

    customer(navigator, customer_id) {
        navigator(`/customers/customer?cid=${customer_id}`) ;
    }

    dealer(navigator, dealer_id) {
        navigator(`/dealers/dealer?did=${dealer_id}`) ;
    }

    deviceView(navigator, device_id, view_type) {
        navigator(`/lift?device=${device_id}&view=${view_type}`) ;
    }

    trigger(navigator, rule_set_id) {
        navigator(`/triggers/trigger?id=${rule_set_id}`);
    }

    triggerType(navigator, rule_set_id, trigger_type) {
        navigator(`/triggers/trigger?id=${rule_set_id}&view=${trigger_type}`);
    }

    triggerEvents(navigator, rule_set_id) {
        navigator(`/triggers/trigger?id=${rule_set_id}&view=2`);
    }

    newTrigger(navigator) {
        navigator(`/triggers/trigger?id=new&rtype=${TriggerType.DeviceTrigger}`);
    }

    newTriggerType(navigator, trigger_type) {
        navigator(`/triggers/trigger?id=new&rtype=${trigger_type}`);
    }

    product(navigator, product_id) {
        navigator(`/products/product?id=${product_id}`) ;
    }

    user(navigator, user_id) {
        navigator(`/admin/user?id=${user_id}`) ;
    }

    back(navigator) {
        navigator(-1) ;
    }

    menuSearch(navigator) {
        navigator("/search");
    }

    noAccess(navigator) {
        navigator("/no-access");
    }

    menuSearchPredefined(navigator, query) {
        navigator(`/search?search=${query}`);
    }

    menuCustomers(navigator) {
        navigator("/customers");
    }

    menuProducts(navigator) {
        navigator("/products");
    }

    menuTriggers(navigator) {
        navigator("/triggers");
    }

    menuAdmin(navigator) {
        navigator("/admin");
    }

    menuAdminView(navigator, view) {
        navigator(`/admin?view=${view}`);
    }

    menuNewLift(navigator) {
        navigator("/new_lift");
    }

    menuAnalytics(navigator) {
        navigator("/analytics");
    }
}


const LinkBuilder = new LinkBuilderClass();
export default LinkBuilder ;


