import Customers from "../api/customerApi";
import moment from "moment";
import HttpCodes from "../library/HttpCodes";


const _custStore = {
    timestamp : null,
    loading : false,
    customers : [],
    customerList : []
}

class CustomerStoreClass {

    refresh = async (force = false) => {
        if (_custStore.loading) {
            return _custStore ;
        }

        if (!force && _custStore.length > 0) {
            return _custStore ;
        }

        try {
            _custStore.loading = true ;
            let custs = await Customers.allCustomers() ;
            if (custs.status === HttpCodes.HttpOK) {
                let c = custs.data
                let dispprods = c.map((c, oi) => {
                    return {"value": c.customer_id, "label": `${c.contact_name}`, "display": `${c.contact_name}`, target: c};
                });

                _custStore.customers = c ;
                _custStore.customerList = dispprods ;
                _custStore.timestamp = moment.utc().format();
            }
        }
        catch(e) {
            console.error("Problem loading customers - ", e.message);
        }
        finally {
            _custStore.loading = false ;
        }

        return _custStore ;
    }
}

const CustomerStore = new CustomerStoreClass();
export default CustomerStore ;

