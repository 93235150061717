// These values map directly to the io_adc_item_name table in the database

export const IOItemTypes = {
    BOT_LIM1_BIT : "1" ,
    ROL2_BIT : "2" ,
    OVRL_BIT : "3" ,
    PRLY_PWR_DET_BIT : "4" ,
    BOT_LIM2_BIT : "5" ,
    DOOR_DSS_BIT : "6" ,
    BALANCE1_BIT : "7" ,
    DRLY_PWR_DET_BIT : "8" ,
    BOT_LIM3_BIT : "9" ,
    LIGHT_GUARD_FRONT_BIT : "10" ,
    TOP_OBST1_BIT : "11" ,
    URLY_PWR_DET_BIT : "12" ,
    BOT_FLIM_BIT : "13" ,
    SPARE2_BIT : "14" ,
    ROL1_BIT : "15" ,
    TOG_DOWN_BIT : "16" ,
    KEYSWITCH_BIT : "17" ,
    DOOR_LSS_BIT : "18" ,
    LIGHT_GUARD_REAR_BIT : "19" ,
    TOP_OBST2_BIT : "20" ,
    DOWN_CAB_BIT : "21" ,
    CHARGER_CONNECTED_BIT : "22" ,
    TOP_FLIM_BIT : "23" ,
    TOG_UP_BIT : "24" ,
    UP_CAB_BIT : "25" ,
    SAFETY_BIT : "26" ,
    SPARE1_BIT : "27" ,
    TOP_OBST3_BIT : "28" ,
    MID_CAB_BIT : "29" ,
    DOOR_HZ_BIT : "30" ,
    BALANCE2_BIT : "31" ,
    TOP_OBST4_BIT : "32" ,
    BOT_LIM4_BIT : "33" ,
    BOT_LIM5_BIT : "34" ,
    BOT_LIM6_BIT : "35" ,
    BOT_LIM7_BIT : "36" ,
    M1_BIT : "37" ,
    M2_BIT : "38" ,
    M3_BIT : "39" ,
    DIP_SWITCH_1 : "40" ,
    DIP_SWITCH_2 : "41" ,
    DIP_SWITCH_3 : "42" ,
    DIP_SWITCH_4 : "43" ,
    DIP_SWITCH_5 : "44" ,
    DIP_SWITCH_6 : "45" ,
    DIP_SWITCH_7 : "46" ,
    DIP_SWITCH_8 : "47" ,
    ROL3_BIT : "48"
}


const ChargerLookupMap = {
    0 : "Charger Relay off – motor in motion",
    1 : "Charger post move settle",
    2 : "Charger post move initial check",
    3 : "Standard charge procedure with health checks",
    4 : "Charger post move not charging",
    255 : "System Reset"
}

const chargerLookup = (value) => {
    return ChargerLookupMap[value] ? ChargerLookupMap[value] : "Unknown"
}

const nullLookup = (value) => {
    return value
}

const chargerValueMap = (v) => {
    return v === 255 ? -1 : v;
}

const chargerTickMap = () => {
    let r = []
    for (let k in ChargerLookupMap) {
        if (k === "255") {
            r.push({v: -1, f: ChargerLookupMap[k]})
        }
        else {
            r.push({v: parseInt(k), f: ChargerLookupMap[k]})
        }
    }

    return r
}


export const TimelineAnalogueOptions = [
    {value: "Voltage",    selected : false, display: "Battery Voltage", target: 'v_bat_voltage_mv', min_max_multiplier: 0.1,  value_map : nullLookup, map_vaxis_tick: null, tooltip_map: nullLookup},
    {value: "LiftAngle",  selected : false, display: "Lift Angle", target: 'l_lift_angle', min_max_multiplier: 0.1,  value_map : nullLookup, map_vaxis_tick: null, tooltip_map: nullLookup},
    {value: "M1CurrentUp",selected : false, display: "M1 Current UP", target: 'a_m1_current_up', min_max_multiplier: 0.1,  value_map : nullLookup, map_vaxis_tick: null, tooltip_map: nullLookup},
    {value: "M2CurrentUp",selected : false, display: "M2 Current UP", target: 'a_m2_current_up', min_max_multiplier: 0.1,  value_map : nullLookup, map_vaxis_tick: null, tooltip_map: nullLookup},
    {value: "M1CurrentDown",selected : false, display: "M1 Current Down", target: 'q_m1_current_down', min_max_multiplier: 0.1,  value_map : nullLookup, map_vaxis_tick: null, tooltip_map: nullLookup},
    {value: "M2CurrentDown",selected : false, display: "M2 Current Down", target: 'q_m2_current_down', min_max_multiplier: 0.1,  value_map : nullLookup, map_vaxis_tick: null, tooltip_map: nullLookup},
    {value: "M1EncCount", selected : false, display: "M1 Encoder Count", target: 'e_motor1_enc_count', min_max_multiplier: 0.0001,  value_map : nullLookup, map_vaxis_tick: null, tooltip_map: nullLookup},
    {value: "M2EncCount", selected : false, display: "M2 Encoder Count", target: 'e_motor2_enc_count', min_max_multiplier: 0.0001,  value_map : nullLookup, map_vaxis_tick: null, tooltip_map: nullLookup},
    {value: "CurPotRead", selected : false, display: "Current Weight Reading", target: 'k_cur_pot_read', min_max_multiplier: 0.1,  value_map : nullLookup, map_vaxis_tick: null, tooltip_map: nullLookup},
    {value: "ChargerStatus", selected : false, display: "Charger Status", target: 'h_charger_status', min_max_multiplier: 0.1, value_map : chargerValueMap, map_vaxis_tick: chargerTickMap, tooltip_map: chargerLookup},
] ;

const get_target_bit_set = (datapoint, source_adc) => {
    switch (source_adc) {
        case "a":
        case "A":
            return datapoint.adc_bits_a
        case "b":
        case "B":
            return datapoint.adc_bits_b
        case "c":
        case "C":
            return datapoint.adc_bits_c
        case "O":
            return datapoint.o_outputs
        default:
            return null
    }
}

export const IODefaultDoorState = () => {
    return {
        valid: false,
        locked: false,
        open: false,
        on_landing: false,
        dss : false,
        hz : false,
        lss : false
    }
}

export const IODoorState = (io_model, datapoint) => {
    let result = IODefaultDoorState() ;
    if (!io_model || !datapoint) {
        return result
    }

    let dss = IOGetRaw(io_model, datapoint, IOItemTypes.DOOR_DSS_BIT) ;
    let hz = IOGetRaw(io_model, datapoint, IOItemTypes.DOOR_HZ_BIT) ;
    let lss = IOGetRaw(io_model, datapoint, IOItemTypes.DOOR_LSS_BIT) ;

    result.on_landing = !lss ;
    result.locked = lss ;
    result.valid = true ;
    result.dss = dss ;
    result.hz = hz ;
    result.lss = lss ;
    // result.open = !dss && !hz && !lss ;
    result.open = !dss ;

    return result ;
}

export const IOGetIfNoPriorChainItem = (io_model, datapoint, io_item) => {
    if (!io_model || !datapoint || !io_item) {
        return false
    }

    if (io_item < IOItemTypes.BOT_LIM1_BIT || io_item > IOItemTypes.DIP_SWITCH_8) {
        return false
    }

    // console.log("IOGetIfNoPriorChainItem: io_item = ", io_item)
    // We've already pick the IO definition in advance (io_model) so we don't need to filter down any further
    for (let sc of io_model.target_io.primary_safety) {
        // console.log("IOGetIfNoPriorChainItem: check = ", sc === io_item, sc, io_item)
        if (sc === io_item) {
            // The bit we're looking for so just run the normal set value
            let rx = IOGet(io_model, datapoint, io_item);
            // console.log("IOGetIfNoPriorChainItem: Found item in primary safety chain = ", io_item, rx)
            return rx ;
        }

        let vx = IOGet(io_model, datapoint, sc);
        if (vx) {
            // console.log("IOGetIfNoPriorChainItem: Found preceding item in primary safety chain = ", sc, ", io_item = ", io_item)
            return false ;
        }
    }

    return false ;
}

export const IOSetIfNoPriorChainItem = (io_model, datapoint, setter, io_item) => {
    if (!io_model || !datapoint || !io_item) {
        return
    }

    if (io_item < IOItemTypes.BOT_LIM1_BIT || io_item > IOItemTypes.DIP_SWITCH_8) {
        return
    }

    /*
     * Do we have a preceding element in the main safety chain which has triggered?
     */

    // for (let sc of io_model.main_safety.safety_chain) {
    //     if (sc.item_name_id === io_item) {
    //         // The bit we're looking for so just run the normal set value
    //         IOSet(io_model, datapoint, setter, io_item) ;
    //         return ;
    //     }
    //
    //     let vx = IOGet(io_model, datapoint, sc.item_name_id)
    //     if (vx) {
    //         // We have a preceding chain item which is set - we can just ignore the set for the selected item
    //         return ;
    //     }
    // }

    // We don't check directional here
}

export const IOGet = (io_model, datapoint, io_item) => {
    // console.log("IOGet: io_model = ", io_model, ", datapoint = ", datapoint, ", io_item = ", io_item)

    // make sure we have all the data we need
    if (!io_model || !datapoint || !io_item || !io_model.target_io) {
        return false
    }

    // restrict to range of valid values
    if (io_item < IOItemTypes.BOT_LIM1_BIT || io_item > IOItemTypes.DIP_SWITCH_8) {
        return false
    }

    // We've already pick the IO definition in advance (io_model) so we don't need to filter down any further
    // console.log("io_model.item_adc_map = ", io_model.item_adc_map)
    let adc_set = io_model.target_io.item_adc_map[io_item]
    // console.log("ADC Set = ", adc_set, io_item)

    if (!adc_set) {
        return false
    }


    // Now we have the adc_set, we need to find the value of the bit in the datapoint
    let bit_set = get_target_bit_set(datapoint, adc_set.adc)
    // console.log("bit_set = ", bit_set)
    if (!bit_set) {
        return ""
    }

    // Now we have the bit_set, we need to find the value of the bit in the datapoint
    let bit_value = bit_set[adc_set.bit - 1]
    // console.log("bit_value check = ", io_item, bit_value, adc_set.trip, bit_value === adc_set.trip)
    return (bit_value === adc_set.trip)
}

export const IOGetRaw = (io_model, datapoint, io_item) => {
    // console.log("IOGet: io_model = ", io_model, ", datapoint = ", datapoint, ", io_item = ", io_item)

    // make sure we have all the data we need
    if (!io_model || !datapoint || !io_item || !io_model.target_io) {
        return false
    }

    // We've already pick the IO definition in advance (io_model) so we don't need to filter down any further
    // console.log("io_model.item_adc_map = ", io_model.item_adc_map)
    let adc_set = io_model.target_io.item_adc_map[io_item]
    // console.log("ADC Set = ", adc_set, io_item)

    if (!adc_set) {
        return false
    }


    // Now we have the adc_set, we need to find the value of the bit in the datapoint
    let bit_set = get_target_bit_set(datapoint, adc_set.adc)
    // console.log("bit_set = ", bit_set)
    if (!bit_set) {
        return ""
    }

    // Now we have the bit_set, we need to find the value of the bit in the datapoint
    return bit_set[adc_set.bit - 1] === '1'
}

export const IOGetRawValue = (io_model, datapoint, io_item) => {
    // console.log("IOGet: io_model = ", io_model, ", datapoint = ", datapoint, ", io_item = ", io_item)

    // make sure we have all the data we need
    if (!io_model || !datapoint || !io_item || !io_model.target_io) {
        return "?"
    }

    // We've already pick the IO definition in advance (io_model) so we don't need to filter down any further
    // console.log("io_model.item_adc_map = ", io_model.item_adc_map)
    let adc_set = io_model.target_io.item_adc_map[io_item]
    // console.log("ADC Set = ", adc_set, io_item)

    if (!adc_set) {
        return "?"
    }

    // Now we have the adc_set, we need to find the value of the bit in the datapoint
    let bit_set = get_target_bit_set(datapoint, adc_set.adc)
    // console.log("bit_set = ", bit_set)
    if (!bit_set) {
        return "?"
    }

    if (adc_set.adc === "O") {
        let raw = bit_set & (1 << (adc_set.bit - 1))
        return raw ? "1" : "0"
    }

    // Now we have the bit_set, we need to find the value of the bit in the datapoint
    return bit_set[adc_set.bit - 1]
}

export const IOSet = (io_model, datapoint, setter, io_item) => {
    // console.log("IOSet: io_model = ", io_model, ", datapoint = ", datapoint, ", io_item = ", io_item)

    // make sure we have all the data we need
    if (!io_model || !datapoint || !io_item || !io_model.target_io) {
        return
    }

    // restrict to range of valid values
    if (io_item < IOItemTypes.BOT_LIM1_BIT || io_item > IOItemTypes.ROL3_BIT) {
        return
    }

    // We've already pick the IO definition in advance (io_model) so we don't need to filter down any further
    let adc_set = io_model.target_io.item_adc_map[io_item]
    // console.log("ADC Set = ", adc_set, io_item)

    if (!adc_set) {
        return
    }


    // Now we have the adc_set, we need to find the value of the bit in the datapoint
    let bit_set = get_target_bit_set(datapoint, adc_set.adc)
    // console.log("bit_set = ", bit_set)
    if (!bit_set) {
        return
    }

    // Now we have the bit_set, we need to find the value of the bit in the datapoint
    let bit_value = bit_set[adc_set.bit - 1]
    // console.log("bit_value check = ", bit_value, adc_set.trip, bit_value === adc_set.trip)
    setter(bit_value === adc_set.trip)
}

export const Safety = {
    shouldCheckDirectionalSafety : (io_model, datapoint) => {
        if (!io_model || !datapoint) {
            return false
        }

        // If on a landing then don't check directional safety elements
        let lss = IOGetRawValue(io_model, datapoint, IOItemTypes.DOOR_LSS_BIT);
        return lss === '1'
    }
}


export const IODefaultLiftState = (io,dvc,dp,land, ds) => {
    return {
        complete: io !== null && dvc !== null && dp !== null && land !== null && ds !== null,
        device: dvc,
        io: io,
        dataset : ds,
        datapoint: dp,
        landings: land,
        is_hb: false,
        door_state: IODefaultDoorState(),
        occupied: false,
        main_safety: {active: false, item: null},
        up_safety: {active: false, item: null},
        down_safety: {active: false, item: null},
        check_dir_safety: false,
        angle: {ok: true, value: 0.0, left: false, right: false},
        weight: 0,
        person_in_lift: false,
        height: 0.0,
        current_floor: null,
        at_bottom_floor: false,
        at_top_floor: false,
        number_of_floors: 0,
        remote_up: false,
        remote_down: false,
        cop_up: false,
        cop_down: false,
        cop_mid: false,
        overloaded: false,
        key_switch: false,
        emergency_stop: false,
        balance1: false,
        balance2: false,
        charger_status: 0,
        wifi_connect_state: 0,
        battery_voltage: 0.0,

        // Top Limits
        top_flim: false,
        top_obst1: false,
        top_obst2: false,
        top_obst3: false,
        top_obst4: false,

        // Bottom Limits
        bot_flim: false,
        bot_lim1: false,
        bot_lim2: false,
        bot_lim3: false,
        bot_lim4: false,
        bot_lim5: false,
        bot_lim6: false,
        bot_lim7: false,

        // Light guards
        light_guard_front: false,
        light_guard_rear: false,

        rol3_bit: false,
        rol2_bit: false,
        prly_pwr_det_bit: false,
        drly_pwr_det_bit: false,

        adc_mapping_available: false,
        adc_a_map: [],
        adc_b_map: [],
        adc_c_map: [],
        output_map: [],
        f301_map : [],
        l475_map : [],
        groups : [],

        l475_runtime_ms : 0,
        f301_runtime_ms : 0,
        rtc : 0,
        last_correction_ms : 0,
    }
}

export const IOGetLiftState = (io_model, datapoint, device, landings, dataset) => {
    // console.log("IOGetLiftState: io_model = ", io_model, ", datapoint = ", datapoint, ", device = ", device, ", landings = ", landings);
    let result = IODefaultLiftState(io_model, device, datapoint, landings, dataset) ;

    if (datapoint && io_model) {
        result.is_hb = datapoint.g_hb === 1
        result.door_state = IODoorState(io_model, datapoint)

        let angle = datapoint.l_lift_angle ;
        if (angle >= 0.6) {
            result.angle = {ok : false, value: angle, left : false, right: true}
        }
        else if (angle <= -0.6) {
            result.angle = {ok : false, value: angle, left : true, right: false}
        }
        else {
            result.angle = {ok : true, value: angle, left : false, right: false}
        }

        result.key_switch = IOGet(io_model, datapoint, IOItemTypes.KEYSWITCH_BIT)
        result.emergency_stop = IOGetIfNoPriorChainItem(io_model, datapoint, IOItemTypes.SAFETY_BIT)
        result.balance1 = IOGetIfNoPriorChainItem(io_model, datapoint, IOItemTypes.BALANCE1_BIT)
        result.balance2 = IOGetIfNoPriorChainItem(io_model, datapoint, IOItemTypes.BALANCE2_BIT)

        result.overloaded = IOGet(io_model, datapoint, IOItemTypes.OVRL_BIT)
        result.cop_up = IOGet(io_model, datapoint, IOItemTypes.UP_CAB_BIT)
        result.cop_down = IOGet(io_model, datapoint, IOItemTypes.DOWN_CAB_BIT)
        result.cop_mid = IOGet(io_model, datapoint, IOItemTypes.MID_CAB_BIT)
        result.remote_up = IOGet(io_model, datapoint, IOItemTypes.TOG_UP_BIT)
        result.remote_down = IOGet(io_model, datapoint, IOItemTypes.TOG_DOWN_BIT)
        result.weight = datapoint.k_cur_pot_read
        result.charger_status = datapoint.h_charger_status
        result.battery_voltage = datapoint.v_bat_voltage_mv / 1000.0
        result.top_flim = IOGet(io_model, datapoint, IOItemTypes.TOP_FLIM_BIT)
        result.top_obst1 = IOGet(io_model, datapoint, IOItemTypes.TOP_OBST1_BIT)
        result.top_obst2 = IOGet(io_model, datapoint, IOItemTypes.TOP_OBST2_BIT)
        result.top_obst3 = IOGet(io_model, datapoint, IOItemTypes.TOP_OBST3_BIT)
        result.top_obst4 = IOGet(io_model, datapoint, IOItemTypes.TOP_OBST4_BIT)

        result.bot_flim = IOGet(io_model, datapoint, IOItemTypes.BOT_FLIM_BIT)
        result.bot_lim1 = IOGet(io_model, datapoint, IOItemTypes.BOT_LIM1_BIT)
        result.bot_lim2 = IOGet(io_model, datapoint, IOItemTypes.BOT_LIM2_BIT)
        result.bot_lim3 = IOGet(io_model, datapoint, IOItemTypes.BOT_LIM3_BIT)
        result.bot_lim4 = IOGet(io_model, datapoint, IOItemTypes.BOT_LIM4_BIT)
        result.bot_lim5 = IOGet(io_model, datapoint, IOItemTypes.BOT_LIM5_BIT)
        result.bot_lim6 = IOGet(io_model, datapoint, IOItemTypes.BOT_LIM6_BIT)
        result.bot_lim7 = IOGet(io_model, datapoint, IOItemTypes.BOT_LIM7_BIT)

        result.light_guard_front = IOGetIfNoPriorChainItem(io_model, datapoint, IOItemTypes.LIGHT_GUARD_FRONT_BIT)
        result.light_guard_rear = IOGetIfNoPriorChainItem(io_model, datapoint, IOItemTypes.LIGHT_GUARD_REAR_BIT)

        result.rol3_bit = IOGet(io_model, datapoint, IOItemTypes.ROL3_BIT)
        result.rol2_bit = IOGet(io_model, datapoint, IOItemTypes.ROL2_BIT)
        result.prly_pwr_det_bit = IOGet(io_model, datapoint, IOItemTypes.PRLY_PWR_DET_BIT)
        result.drly_pwr_det_bit = IOGet(io_model, datapoint, IOItemTypes.DRLY_PWR_DET_BIT)
        result.check_dir_safety = result.door_state.lss ;

        result.l475_runtime_ms = datapoint.t_l475_runtime_ms
        result.f301_runtime_ms = datapoint.t_f301_runtime_ms
        result.rtc = datapoint.t_rtc
        result.last_correction_ms = datapoint.t_last_correction_ms
    }

    if (device) {
        result.wifi_connect_state = device.wifi_connect_state_id
        result.person_in_lift = result.weight > 0 && result.weight >= (device.high_weight * 0.75)
        result.occupied = result.weight > 0 && result.weight >= (device.high_weight * 0.75)
        result.number_of_floors = device.number_of_floors
    }

    // TODO - fill in the rest of the data
    // first start with working out the current floor based on the landing data
    // Need to actual build a map for the adc a,b and c which include whether tripped and the actual item label

    if (io_model?.target_io && datapoint) {
        result.adc_mapping_available = true

        // Now need to fill in the adc mapping
        for (let a of io_model.target_io.adca) {
            let target = io_model.target_io.item_adc_map[a]
            let base = io_model.def.adc_item_map[a]
            if (base && target) {
                let raw = datapoint.adc_bits_a[target.bit - 1]
                let safety = io_model.target_io.primary_safety.includes(a)

                result.adc_a_map.push({
                    io : a,
                    adc : target.adc,
                    label: base.io_name,
                    desc : base.io_desc,
                    value : raw,
                    trip: target.trip,
                    tripped: raw === target.trip,
                    bit: target.bit,
                    safety: safety,
                    stxt : (raw === "1") ? target.hl : target.ll,
                    trc : target.trc,
                    sc : target.sc
                })
            }
        }

        for (let a of io_model.target_io.adcb) {
            let target = io_model.target_io.item_adc_map[a]
            let base = io_model.def.adc_item_map[a]
            if (base && target) {
                let raw = datapoint.adc_bits_b[target.bit - 1]
                let safety = io_model.target_io.primary_safety.includes(a)

                result.adc_b_map.push({
                    io : a,
                    adc : target.adc,
                    label: base.io_name,
                    desc : base.io_desc,
                    value : raw,
                    trip: target.trip,
                    tripped: raw === target.trip,
                    bit: target.bit,
                    stxt : (raw === "1") ? target.hl : target.ll,
                    trc : target.trc,
                    sc : target.sc
                })
            }
        }

        for (let a of io_model.target_io.adcc) {
            let target = io_model.target_io.item_adc_map[a]
            let base = io_model.def.adc_item_map[a]
            if (base && target) {
                let raw = datapoint.adc_bits_c[target.bit - 1]
                let safety = io_model.target_io.primary_safety.includes(a)

                result.adc_c_map.push({
                    io : a,
                    adc : target.adc,
                    label: base.io_name,
                    desc : base.io_desc,
                    value : raw,
                    trip: target.trip,
                    tripped: raw === target.trip,
                    bit: target.bit,
                    safety: safety,
                    stxt : (raw === "1") ? target.hl : target.ll,
                    trc : target.trc,
                    sc : target.sc
                })
            }
        }

        // console.log("IO Outputs = ", io_model.target_io)
        for (let a of io_model.target_io.outputs) {
            let target = io_model.target_io.item_adc_map[a]
            let base = io_model.def.adc_item_map[a]
            if (base && target) {
                let raw = (datapoint.o_outputs & (1 << target.bit)) ;

                result.output_map.push({
                    io : a,
                    adc : target.adc,
                    label: base.io_name,
                    desc : base.io_desc,
                    value : raw,
                    trip: target.trip,
                    tripped: raw === target.trip,
                    bit: target.bit,
                    safety: false,
                    stxt : (raw === "1") ? target.hl : target.ll,
                    trc : target.trc,
                    sc : target.sc
                })
            }
        }

        // bit 6 of adcc is light timer setting bit 0 and bit 7 of adcc is light timer setting bit 1
        // when bit 0 is 0 and bit 1 is 0 then the light time is 10seconds
        // when bit 0 is 0 and bit 1 is 1 then the light time is 20seconds
        // when bit 0 is 1 and bit 1 is 0 then the light time is 45seconds
        // when bit 0 is 1 and bit 1 is 1 then the light time is 90seconds
        // i need to set a flag on result to indicate the light timer setting
        let bit0 = datapoint.adc_bits_c[5]
        let bit1 = datapoint.adc_bits_c[6]
        let bits = `${bit0}:${bit1}`
        switch (bits) {
            case "0:0":
                result.light_timer = 10
                break
            case "0:1":
                result.light_timer = 20
                break
            case "1:0":
                result.light_timer = 45
                break
            case "1:1":
                result.light_timer = 90
                break
            default:
                result.light_timer = 10
        }

        for (let g of io_model.target_io.groups) {
            let group = {
                name: g.name,
                desc: g.desc,
                items: []
            }

            let flag = false ; // g.name === "Outputs" ;

            for (let item of g.io) {
                let target = io_model.target_io.item_adc_map[item]
                let base = io_model.def.adc_item_map[item]

                if (flag) {
                    console.log("Output Item = ", item, target, base, datapoint.o_outputs)
                }
                if (base && target) {
                    let raw = IOGetRawValue(io_model, datapoint, item)
                    let safety = io_model.target_io.primary_safety.includes(item)

                    if (flag) {
                        console.log("Output Item 2 = ", raw, safety)
                    }

                    group.items.push({
                        io : item,
                        adc : target.adc,
                        label: base.io_name,
                        desc : base.io_desc,
                        value : raw,
                        trip: target.trip,
                        tripped: raw === target.trip,
                        bit: target.bit,
                        safety: safety,
                        stxt : (raw === "1") ? target.hl : target.ll,
                        trc : target.trc,
                        sc : target.sc
                    })
                }
            }

            result.groups.push(group)
        }
    }

    console.log("IO Definition Mapped Result = ", result)
    return result
}
















